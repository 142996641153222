import React, {Component} from 'react'

export default class ScroolToTop extends Component{
    constructor(props){
        super(props);
        this.state={
            isVisible : false
        }
    }
    componentDidMount(){
        var scrollComponent = this;
        document.addEventListener("scroll", function(e){
            scrollComponent.toggleVisibility();
        })
    }
    toggleVisibility(){
        if(window.pageYOffset > 100){
            this.setState({isVisible:true})
        }else{
            this.setState({isVisible: false})
        }
    }

    scrollToTop(){
        window.scrollTo({
            top:0,
            behavior: "smooth"
        })
    }

    render(){
        const {isVisible} = this.state;
        return(
            <div className="scrollToTop">
                {isVisible && (
                    <div className="topIcon" onClick={()=> this.scrollToTop()}>
                        <i className="fa fa-arrow-up"></i>
                    </div>
                )}
            </div>
        )
    }
}