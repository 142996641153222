import React, { useState, useEffect } from 'react'

const SearchResultTitle = ({ showTitle, loading }) => {
    const [getTitle, setTitle] = useState();

    useEffect(() => {
        let getrel = showTitle.relation;
        let getVal = showTitle.value;
        if (getrel === 'eq') {
            setTitle(getVal + " results found for the search")
        } else if (getrel === 'gte') {
            setTitle("More than " + getVal + " results found for the search")
        }
        const reload = ()=>{
            window.location.reload();
        }
        const setTimeOut=()=>{
            setTimeout(reload,1000); // call after 10 sec and one time only
            console.log("Set time out Call for time set")
        }
        
        // Check Filter Value if it's 0 then call reload function
        if(getVal == 0){
           // setTimeOut();
        }
    })


    if (!loading) {
        return <h1>Loading...</h1>;
    }
    return <>
        {getTitle}
    </>
}

export default SearchResultTitle