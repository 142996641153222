import React, { Component } from 'react'

import { Row, Col, Image, Navbar, Nav, NavDropdown ,Container } from 'react-bootstrap'
import Logo from '../../assets/images/kailasa-emblem-web-400.png'
import SearchBox from '../../components/searchBox'
import TopMainNavigation from '../topnav/topmainnav'
import { Link } from 'react-router-dom';

export default () => {

    let sessionData = sessionStorage.getItem('userData');
	let roles = null;
	if (sessionData)
	{
		console.log(sessionData)
		let roleObj = JSON.parse(sessionData);
		roles = roleObj.role
		//if (sessionData != null && 
		console.log(roleObj.role)
	}
	
    return (
        <>
            <div>
                <Row>
                    <Col sm={1}>
                        <Image src={Logo} width="50" className="site-logo pull-right" />
                    </Col>
                    <Col sm={9} className="topTitle">
                        <p>Kailasa’s Nithyananda Hindu Digital Library</p>
                    </Col>
                    <Col sm={1} className="top-right-link-col">
                        <Link to='/r/register' className="top-link">{1 == 2 ? "REGISTER" : ""}</Link>
                    </Col>
                    <Col sm={1} className="top-right-link-col">
                        <Link to='/r/login' className="top-link">{sessionData == null ? "LOGIN" : ""}</Link>
                    </Col>
                </Row>
                <Row>
                    <Col sm={1}>
                    </Col>
                    <Col>
                        <Navbar bg="white" expand="lg" className="top-navbar">
                            <Container>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav>
                                        <Nav.Link href="/r/home" className="top-link first-link">HOME</Nav.Link>
                                        <NavDropdown title="ABOUT US" className="top-link">
                                            <NavDropdown.Item href="https://gov.shrikailasa.org/about/" target="_blank">About Kailasa</NavDropdown.Item>
											<NavDropdown.Item href="https://gov.shrikailasa.org/reviver/" target="_blank">About Founder</NavDropdown.Item>
                                        </NavDropdown>
										<Nav.Link href="/r/popular-titles" className="top-link">POPULAR TITLES </Nav.Link>
										<Nav.Link href="/r/scriptures" className="top-link">DIGITAL CATALOGUE </Nav.Link>
										<Nav.Link href="/r/swamijis-books" className="top-link">ŞPH COLLECTION </Nav.Link>
										{
											roles != null && roles.includes('admin') ? 
												<NavDropdown title="CATALOGING" className="top-link">
													<NavDropdown.Item href="/r/assign-review">
														Manage Reviews
													</NavDropdown.Item>
													<NavDropdown.Item href="/r/review">
														Review
													</NavDropdown.Item>												
												</NavDropdown>
											: 
											null
										}
										{
											roles != null && roles.includes('reviewer') && !roles.includes('admin')  ? 
												<NavDropdown title="CATALOGING" className="top-link">
													<NavDropdown.Item href="/r/review">
														Review
													</NavDropdown.Item>												
												</NavDropdown>
											: 
											null
										}										
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </Col>
                </Row>
            </div>
        </>
    )


}

