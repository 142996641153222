import React, { useState } from 'react'

const ReviewNavList = ({ reviewCategory, loading, refSelectedData, refSelectedReviewCategoryTreeID, selectedCategoryID,refSelectedCategoryElement }) => {

    const toggleNav = (e) => {
        let resultTargetChildNavElement;
        let resultTargetElement;
        let selectedElementId = e.target.id;
        let desiredULElementId = "ul" + selectedElementId.slice(4);
        let targetChildNavElement = document.getElementById(desiredULElementId);
        let targetElement = document.getElementById(e.target.id);
        if (targetChildNavElement !== null) {
            let targetChildNavElementClassList = targetChildNavElement.classList;
            if (targetChildNavElementClassList !== null) {
                resultTargetChildNavElement = targetChildNavElementClassList.toggle('active');
            }
        }
        // For icon toggle
        if (targetElement !== null) {
            let targetElementClassList = targetElement.classList;
            if (targetElementClassList !== null) {
                resultTargetElement = targetElementClassList.toggle('active');
            }
        }
        return (
            resultTargetChildNavElement, resultTargetElement

        )
    }

    const renderMenu = () => reviewCategory.map((menuItem, i) =>

        <li key={i}>{(typeof (menuItem.menu) == "object") ? <span className="showChildNav" onClick={(event) => toggleNav(event)}><i className="fa fa-caret-right" id={"span_" + menuItem.name + "_" + i}></i></span> : null}
            {(selectedCategoryID != null && selectedCategoryID == "span_" + menuItem.name + "_" + i) ?
                <span id={"span_" + menuItem.name + "_" + i} tree_id={menuItem.name} className="leftNavSpan activeElement" onClick={(e) => showFilterData(menuItem.name, "span_" + menuItem.name + "_" + i,menuItem.name)}>{menuItem.name}</span>
                :
                <span id={"span_" + menuItem.name + "_" + i} tree_id={menuItem.name} className="leftNavSpan" onClick={(e) => showFilterData(menuItem.name, "span_" + menuItem.name + "_" + i,menuItem.name)}>{menuItem.name}</span>
            }
            {(typeof (menuItem.menu) == "object") ? <ul className="menuLevelOne" id={"ul_" + menuItem.name + "_" + i}>
                {
                    menuItem.menu.map((menuSubItem, ii) =>
                        <li key={(Math.random() * 2000) + ii}>
                            {(typeof (menuSubItem.menu) == "object") ? 
                            <span className="showChildNav" onClick={(event) => toggleNav(event)}>
                                <i id={"span_" + menuItem.name + "_" + i + "_" + menuSubItem.name + "_" + ii} className=
                                {selectedCategoryID != null && selectedCategoryID.indexOf(menuItem.name + "_" + i + "_" + menuSubItem.name + "_" + ii) >0?
                                "fa fa-caret-right active"
                                :"fa fa-caret-right"
                                }/>
                            </span> 
                            : null}
                            {(selectedCategoryID != null && selectedCategoryID == "span_" + menuItem.name + "_" + i + "_" + menuSubItem.name + "_" + ii) ?
                                <span id={"span_" + menuItem.name + "_" + i + "_" + menuSubItem.name + "_" + ii} 
                                tree_id={ menuSubItem.name+"/"+menuItem.name} 
                                className="leftNavSpan activeElement" onClick={(e) => showFilterData(menuSubItem.name, "span_" + menuItem.name + "_" + i + "_" + menuSubItem.name + "_" + ii,menuSubItem.name+"/"+menuItem.name)}>{menuSubItem.name}</span>
                                :
                                <span id={"span_" + menuItem.name + "_" + i + "_" + menuSubItem.name + "_" + ii} 
                                tree_id={ menuSubItem.name+"/"+menuItem.name} 
                                className="leftNavSpan" onClick={(e) => showFilterData(menuSubItem.name, "span_" + menuItem.name + "_" + i + "_" + menuSubItem.name + "_" + ii,menuSubItem.name+"/"+menuItem.name)}>{menuSubItem.name}</span>
                            }


                            {(typeof (menuSubItem.menu) == "object") ?
                                
                                 <ul id={"ul_" + menuItem.name + "_" + i + "_" + menuSubItem.name + "_" + ii} className=
                                        {selectedCategoryID != null && selectedCategoryID.indexOf(menuItem.name + "_" + i + "_" + menuSubItem.name + "_" + ii) >0? 
                                        "menuLevelTwo active"
                                        :"menuLevelTwo"
                                 }>

                                    {
                                        menuSubItem.menu.map((menuThreeItem, iii) =>
                                            <li key={(Math.random() * 2000) + i}>
                                                {(typeof (menuThreeItem.menu) == "object") ? <span className="showChildNav" onClick={(event) => toggleNav(event)}><i className="fa fa-caret-right" id={"span_" + iii}></i></span> : null}
                                                {(selectedCategoryID != null && selectedCategoryID == "span_" + menuItem.name + "_" + i + "_" + menuSubItem.name + "_" + ii + "_" + menuThreeItem.name) ?
                                                    <span id={"span_" + menuItem.name + "_" + i + "_" + menuSubItem.name + "_" + ii + "_" + menuThreeItem.name} tree_id={ menuThreeItem.name +"/"+menuSubItem.name+"/"+menuItem.name} className="leftNavSpan activeElement" onClick={(e) => showFilterData(menuThreeItem.name, "span_" + menuItem.name + "_" + i + "_" + menuSubItem.name + "_" + ii + "_" + menuThreeItem.name,menuThreeItem.name +"/"+menuSubItem.name+"/"+menuItem.name)}>{menuThreeItem.name}</span>
                                                    :
                                                    <span id={"span_" + menuItem.name + "_" + i + "_" + menuSubItem.name + "_" + ii + "_" + menuThreeItem.name} tree_id={ menuThreeItem.name +"/"+menuSubItem.name+"/"+menuItem.name} className="leftNavSpan" onClick={(e) => showFilterData(menuThreeItem.name,"span_" + menuItem.name + "_" + i + "_" + menuSubItem.name + "_" + ii + "_" + menuThreeItem.name, menuThreeItem.name +"/"+menuSubItem.name+"/"+menuItem.name)}>{menuThreeItem.name}</span>
                                                }
                                            </li>
                                        )
                                    }

                                </ul>
                                : null}
                        </li>
                    )
                }
            </ul> : null}
        </li>);

    const showFilterData = (evt, id,treeId) => {
        refSelectedData(evt);
        refSelectedCategoryElement(id);
        refSelectedReviewCategoryTreeID(treeId);

        var getSelectedElementId = document.getElementById(evt);
        if (getSelectedElementId !== null) {
            var leftNavSpanElements = document.getElementsByClassName("leftNavSpan");
            var currentId = getSelectedElementId.innerHTML;

            for (var ii = 0; ii < leftNavSpanElements.length; ii++) {
                if (leftNavSpanElements[ii] != null && leftNavSpanElements[ii].classList != null) {
                    if (leftNavSpanElements[ii].id !== currentId) {
                        leftNavSpanElements[ii].classList.remove("activeElement");
                    } else {
                        leftNavSpanElements[ii].classList.add("activeElement")
                    }

                }
            }
        }

    }

    if (!loading) {
        return <h2>Loading...</h2>;
    }
    else {
        return (
            <ul className="taxonomyMenu maxHeight scriptures">
                {renderMenu()}
            </ul>
        )
    }
}
export default ReviewNavList;

