import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'

import ReviewModel from './reviewModel'
import { useEffect } from 'react';

const ShowReviewdData = ({ posts, loading, curContext }) => {
	const [context, setContext] = useState(curContext);
    const [modalDisplayStatus, setModalDisplayStatus] = useState('hide');
    const [getReviewId, setReviewId] = useState()
    const [reviewItemId,setReviewItemId] = useState();
    const [reviewItemAuthor,setReviewItemAuthor] = useState();
    const [reviewItemTitle, setReviewItemTitle] = useState();
    const [curReviewData, setCurReviewData] = useState();
	const [actionText, setActionText] = useState('Submit Review');

    useEffect( async () => {
		if (context == "approve")
		{
			setActionText('Approve Review');
		}
		
		
    }, [context])
	
    if (!loading) {
        return <h2>Loading...</h2>;
    }

	const initializeReviewData = (data) =>{
		data.abstract = data.abstract??"";
		data.comment = data.comment??"";
		data.reviewer = data.reviewer??"";
		data.author = data.author??"";
		data.publisher = data.publisher??"";
		data.copyrightFreeStatus = data.copyrightFreeStatus??false;
		data.publicationYear = data.publicationYear??"";
		data.copyrightYear = data.copyrightYear??"";
		data.categories = data.categories??[];
		data.url = data.url??"";
		data.copyrightHolder = data.copyrightHolder??"";
		data.recKey = data.recKey;
		data.subject = data.subject??"";
		data.title = data.title??"";
		data.language = data.language??"";
		data.tags =data.tags??"";
		
	}

    const showReviewModal = (id, title, author) => {
		console.log(id);
        var queryUrl = '/review/adminreview/fetch?id=' + id;
        fetch(queryUrl)
        .then((resp) => resp.json())
        .then(function (data) {
			initializeReviewData(data);
			console.log(data);
            setCurReviewData(data);
            setModalDisplayStatus('show');			
            setReviewItemId(id);
            setReviewItemAuthor(data.author);
            setReviewItemTitle(data.title);
        })
        .catch(function (error) {
            console.log(JSON.stringify(error));
        })
    }

    // Set Modal Close
    const getDataForModalClose = setDataForModal => setModalDisplayStatus(setDataForModal);

    return( 
	<>
	<div className="rowDataDisplay ">
                {posts.map((item, i) => {
                    var review_status = "";
                    if (item._source.review_status === null || item._source.review_status === undefined) {
                        review_status = "Not Reviewed";
                    }
                    else {
                        review_status = item._source.review_status;
                    }
                    if(item)
            		return (
                		<Row key={i}>
		                    <Col sm={3}>
                		        <span className="font-weight-bold">Title:</span> {item._source.title}<br />
                        		<span className="font-weight-bold">Subject:</span> {item._source.subject}<br />
                       			 <span className="font-weight-bold">Author:</span> {item._source.author}<br />
                    		    </Col>
                    		    <Col sm={5}>
                        		<span className="font-weight-bold">Abstract:</span> <span className="overFlowtexts">{item._source.abstract}</span>
                    		    </Col>
                    		    <Col sm={1}>
                        		<span className="font-weight-bold">Review Status:</span> <span className="overFlowtexts">{item._source.review_status}</span>
                    		    </Col>								

								<Col sm={2}>
								<span className="review" id={item._id} onClick={() => showReviewModal(item._id, item._source.title, item._source.author)}> {actionText} </span><br />
								</Col>								
                		</Row>
           		 )	
       		 })
       	 	}
    	</div>
    	{modalDisplayStatus !== 'hide' ?
                <ReviewModel
                    curReviewData={curReviewData}
                    getModalStatus={modalDisplayStatus} 
                    getDataForModalClose={getDataForModalClose} 
                    reviewItemId={reviewItemId}
                    reviewItemAuthor={reviewItemAuthor}
                    reviewItemTitle={reviewItemTitle}
					curContext = {context}
         	/>
    		: null}
    	</>
   )
}
export default ShowReviewdData;
