import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'

import ReviewModel from './reviewModel'
import { useEffect } from 'react';

const ShowSelectedData = ({ posts, loading }) => {
    const [modalDisplayStatus, setModalDisplayStatus] = useState('hide');
    const [getReviewId, setReviewId] = useState()
    const [reviewItemId,setReviewItemId] = useState();
    const [reviewItemAuthor,setReviewItemAuthor] = useState();
    const [reviewItemTitle, setReviewItemTitle] = useState();
    const [curReviewData, setCurReviewData] = useState();

    if (!loading) {
        return <h2>Loading...</h2>;
    }

    const showReviewModal = (id, title, author) => {
        var queryUrl = '/customerinput/adminreview?id=' + id;
        fetch(queryUrl)
        .then((resp) => resp.json())
        .then(function (data) {
            if ( data === undefined || data === null )
                data = {
                    "review": {
                        "categories":[]
                        }
                };
            setCurReviewData(data.review);
            setModalDisplayStatus('show');
            setReviewItemId(id);
            setReviewItemAuthor(author);
            setReviewItemTitle(title);
        })
        .catch(function (error) {
            console.log(JSON.stringify(error));
        })
    }

    // Set Modal Close
    const getDataForModalClose = setDataForModal => setModalDisplayStatus(setDataForModal);

    return( 
	<>
	<div className="rowDataDisplay ">
                {posts.map((item, i) => {
                    var review_status = "";
                    if (item._source.review_status === null || item._source.review_status === undefined) {
                        review_status = "Not Reviewed";
                    }
                    else {
                        review_status = item._source.review_status;
                    }
                    if(item)
            		return (
                		<Row key={i}>
		                    <Col sm={4}>
                		        <span className="font-weight-bold">Title:</span> {item._source.title}<br />
                        		<span className="font-weight-bold">Subject:</span> {item._source.subject}<br />
                       			 <span className="font-weight-bold">Author:</span> {item._source.author}<br />
                    		    </Col>
                    		    <Col sm={5}>
                        		<span className="font-weight-bold">Abstract:</span> <span className="overFlowtexts">{item._source.abstract}</span>
                    		    </Col>
                    		    <Col sm={1}>
                                <a href={item._source.url} target="_blank">Download/Read</a>
                                </Col>
                    		<>
                        	  {	
                           		 (item._source.category === null || item._source.category === undefined || item._source.category === 'Uncategorised')?
                             	     <Col sm={2}>
                                            <span class="review" id={item._id} onClick={() => showReviewModal(item._id, item._source.title, item._source.author)}> Submit Review </span><br />

                                            {/*<span className="font-weight-bold">Status:</span> {review_status}<br />*/}
                                     </Col>
                                	:""
	                          }
          	                </>
                		</Row>
           		 )	
       		 })
       	 	}
    	</div>
    	{modalDisplayStatus !== 'hide' ?
                <ReviewModel
                    curReviewData={curReviewData}
                    getModalStatus={modalDisplayStatus} 
                    getDataForModalClose={getDataForModalClose} 
                    reviewItemId={reviewItemId}
                    reviewItemAuthor={reviewItemAuthor}
                    reviewItemTitle={reviewItemTitle}
         	/>
    		: null}
    	</>
   )
}
export default ShowSelectedData;
