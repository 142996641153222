import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import VideoDisplay from '../../components/herosection-video.jsx'
import dbdata from '../../assets/dataconfig/taxonomyData.json'

export default class TopNav3 extends Component {
    getPageElements = () => {
        let pageElements = [];
        dbdata.taxonomy.map((contents, key) => {
            if (contents.pageIdentifier === "TopNav2") {
                pageElements.push(contents)
            }
        })
        return pageElements
    }
    render() {
        
        
        return (
            <>
                <Row>
                    {this.getPageElements().map((pageElements, keys) =>
                        <>
                            {typeof pageElements.pagevideo !== "object" ?
                                <>
                                    <Col sm={12} className="container-content">
                                        {pageElements.pagecontent.map((innerContent, key) =>
                                            <div id={pageElements.pageIdentifier + "_" + innerContent.pageContentId + "_"+ key}>
                                                <h1 id={pageElements.pageIdentifier + "_" + innerContent.pageContentId + "_"+ key + "_Title"}>{innerContent.title}</h1>
                                                <div id={pageElements.pageIdentifier + "_" + innerContent.pageContentId + "_"+ key + "_Content"} dangerouslySetInnerHTML={{ __html: innerContent.content }} />
                                            </div>
                                        )}
                                    </Col>
                                </>
                            : null}
                            {typeof pageElements.pagevideo == "object" ?
                                <>
                                    <Col sm={9} className="container-content">
                                        {pageElements.pagecontent.map((innerContent, key) =>
                                            <div id={pageElements.pageIdentifier + "_" + innerContent.pageContentId + "_"+ key}>
                                                <h1 id={pageElements.pageIdentifier + "_" + innerContent.pageContentId + "_"+ key + "_Title"}>{innerContent.title}</h1>
                                                <div id={pageElements.pageIdentifier + "_" + innerContent.pageContentId + "_"+ key + "_Content"} dangerouslySetInnerHTML={{ __html: innerContent.content }} />
                                            </div>
                                        )}
                                    </Col>
                                    <Col sm={3}>
                                        {pageElements.pagevideo.map((innerContent, key) =>
                                            <div id={pageElements.pageIdentifier + "_" + innerContent.videoContentId + "_"+ key}>
                                                <VideoDisplay title={innerContent.videoTitle} url={innerContent.videourl} />
                                            </div>
                                        )}
                                    </Col>
                                </>
                            : null}
                        </>
                    )}
                </Row>
            </>
        )
    }
}