import React from 'react';
import { Image } from 'react-bootstrap';
import Logo from '../assets/images/kailasa-emblem-web-400.png';

const BookHeaderLogo = () => {
  return (
    <div className="book-reader-header">
      <Image src={Logo} width="40" />
      <p>Kailasa’s Nithyananda Hindu Digital Library</p>
    </div>
  );
};

export default BookHeaderLogo;

