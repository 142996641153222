import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Row, Col, Checkbox } from 'react-bootstrap'
import ReviewNavList from './reviewNavList'
import TaxonomyNav from '../assets/dataconfig/scriptnav.json'


const ReviewModel = ({ getModalStatus, getDataForModalClose, reviewItemId, reviewItemAuthor,
    reviewItemTitle, curReviewData, curContext }) => {

	const [context , setContext] = useState(curContext)	
    const [modalStatus, setModalStatus] = useState(getModalStatus);
	const [origReviewData, setOrigReviewData] = useState(curReviewData);
    const [copyRightFreeStatus, setCopyRightFreeStatus] = useState(curReviewData.copyrightFreeStatus);
    const [publicationYear, setPublicationYear] = useState(curReviewData.publicationYear);
    const [titleChange, setTitleChange] = useState(curReviewData.title);
    const [authorChange, setAuthorChange] = useState(curReviewData.author);
    const [languageChange, setLanguageChange] = useState(curReviewData.language);
    const [publisherChange, setPublisherChange] = useState(curReviewData.publisher);
    const [copyrightYear, setCopyrightYear] = useState(curReviewData.copyrightYear);
    const [copyrightHolder, setCopyrightHolder] = useState(curReviewData.copyrightHolder);
    const [loading, setLoading] = useState(true);
    const [getReviewCategoryData, setReviewCategoryData] = useState([]);
    const [getSelectedValue, setSelectedValue] = useState([]);
    const [getSelectedReviewCategoryID, setSelectedReviewCategoryID] = useState('');
    const [getSelectedCategoryTreeID, setSelectedCategoryTreeID] = useState(curReviewData.categories);
    const [abstractTextAreaValue, setAbstractTextAreaValue] = useState(curReviewData.abstract);
    const [commentTextAreaValue, setCommentTextAreaValue] = useState(curReviewData.comments);
	const [keywords, setKeywords] = useState(curReviewData.keywords);
	const [removalFlag, setRemovalFlag] = useState(curReviewData.removalFlag);
    const [message, setMessage] = useState();
	const [errorMessage , setErrorMessage ] = useState("");
	const [saveBtnText, setSaveBtnText] = useState('Save');
	const [cancelBtnText, setCancelBtnText] = useState('Cancel');

    useEffect(() => {
        const fetchAllPosts = async () => {
            setReviewCategoryData(TaxonomyNav.taxonomy);
        }
        fetchAllPosts();
		if (context == "approve")
		{
			setSaveBtnText( "Approve" );
			setCancelBtnText("Reject");
		}
        
    }, [getSelectedCategoryTreeID]);

    // Set Data as per the Selected Item
    const getSelectData = (setSelectData) => setSelectedValue([...getSelectedValue, ...setSelectData]);
    const getSelectedReviewCategoryIDData = (setIDData) => setSelectedReviewCategoryID(setIDData);
    const getSelectReviewCategoryTreeIDData = (setTreeIDData) => setSelectedCategoryTreeID([...getSelectedCategoryTreeID, setTreeIDData]);

    const handleClose = () => {
        setModalStatus('hide');
        getDataForModalClose('hide');
		console.log("Context : " + context);
		if(context == "assign")
		{
			console.log("Reload called");
			window.location.reload();
		}
    }
	const handleMarkForRemoval = (event) => {
		setRemovalFlag(event.target.checked);
	}
	const handleKeywordChange = (event) => {
        setKeywords(event.target.value);
    }

    const handlePublicationYearChange = (event) => {
        setPublicationYear(event.target.value);
    }
    const handleTitleChange = (event) => {
        setTitleChange(event.target.value);
    }
    const handleAuthorChange = (event) => {
        setAuthorChange(event.target.value);
    }
    const handleLanguageChange = (event) => {
        setLanguageChange(event.target.value);
    }
    const handlePublisherChange = (event) => {
        setPublisherChange(event.target.value);
    }
    const handleCopyrightYear = (event) => {
        setCopyrightYear(event.target.value);
    }
    const handleCopyrightHolder = (event) => {
        setCopyrightHolder(event.target.value);
    }
    const handleAbstractChange = (event) => {
        setAbstractTextAreaValue(event.target.value);
    }
    const handleCommentChange = (event) => {
        setCommentTextAreaValue(event.target.value);
    }
    const handleCopyrightChange = (event) => {
        console.log("Checkbox Change" + event.target.checked);
        setCopyRightFreeStatus(event.target.checked)
    }

    const deleteSelected = (evt) => {
        let currentCatgoriesCopy = [...getSelectedCategoryTreeID];
        console.log("Initial currentCatgories are " + currentCatgoriesCopy);
        currentCatgoriesCopy.splice(evt, 1);
        {/*this.setState({
            getSelectedCategoryTreeID: currentCatgoriesCopy
        });*/}
        setSelectedCategoryTreeID(currentCatgoriesCopy);
        //console.log("Final currentCatgories are " + getSelectedCategoryTreeID);
    }
	const rejectReview = () => {
	}

    const saveReview = () => {
        console.log("Save It");

        let body = {
            "reviewer": origReviewData.reviewer,
			"review_status" : "Submitted",
            "recKey": reviewItemId,
            "title": titleChange,
            "author": authorChange,
			"categories": getSelectedCategoryTreeID,
			"abstract": abstractTextAreaValue,
			"comments": commentTextAreaValue,
			"publicationYear": publicationYear,
			"copyrightFreeStatus": copyRightFreeStatus,
			"language": languageChange,
			"publisher": publisherChange,
			"copyrightYear": copyrightYear,
			"copyrightHolder": copyrightHolder,
			"keywords":keywords,
			"removalFlag":removalFlag,
			"url": origReviewData.url
        };
		if(titleChange.length == 0)
		{
			setErrorMessage("Title is mandatory");
			return;
		}
		if(languageChange.length == 0)
		{
			setErrorMessage("Language is mandatory");
			return;
		}
		if(authorChange.length == 0)
		{
			setErrorMessage("Author is mandatory");
			return;
		}
		setErrorMessage("")
        console.log(body);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        };
		let url = '/review/adminreview/submitreview';
		if (context == "approve")
			url = '/review/adminreview/acceptreview';
		
		console.log(url);
			
        fetch(url  , requestOptions)
        .then(response => {
            if (response.ok) {
                handleClose();
            } else {
                setMessage("There is some issue in saving the review!!");
            }
        }).catch(error => {
            console.log("Errr code" + error);
            setMessage("There is some issue in saving the review!!");
        });
    }

    return (
        <>
            <div id="modalOpen" className="modal-open">
                <div className={"fade modal-backdrop " + modalStatus}></div>
                <div className={"fade modal " + modalStatus} >
                    <Modal.Dialog className="reviewModal">
                        <Modal.Header>
                            <Modal.Title>Review for "{reviewItemTitle}" by "{reviewItemAuthor}" </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col sm={12} className="closeIcon">
                                    <i onClick={handleClose} className="fa fa-close" />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4} >
                                    <h5>Categories</h5>
                                    <ReviewNavList reviewCategory={getReviewCategoryData} loading={loading} refSelectedData={getSelectData} refSelectedReviewCategoryTreeID={getSelectReviewCategoryTreeIDData} selectedCategoryID={getSelectedReviewCategoryID} refSelectedCategoryElement={getSelectedReviewCategoryIDData} />
                                </Col>
                                <Col sm={4} >
                                    <h5>Selected Categories:</h5>
                                    {message != '' ?
                                        (<h5 className="deleteIcon">{message} </h5>)
                                        : null}
                                    {getSelectedCategoryTreeID != '' ?
                                        (<><ul className="selectedItem">{
                                            getSelectedCategoryTreeID.map((item, i) =>
                                                <li key={i} onClick={() => deleteSelected(i)}>
                                                    {item} <i className="fa fa-remove deleteIcon" />
                                                </li>)}
                                        </ul>
                                        </>)
                                        : null}
                                </Col>
                                <Col sm={4}>
                                    <h5>Abstract</h5>
                                    <textarea value={abstractTextAreaValue} onChange={handleAbstractChange} className="abstractTextarea" />
									<a href={origReviewData.url} target="_blank">Download/Read</a>
									<h3 style={{color:"red"}}>{errorMessage}</h3>
                                </Col>
                            </Row>
                            <Row className="noMargin">
                                <Col sm={3}>
                                    <h5>Title</h5>
                                    <textarea rows="1" value={titleChange} onChange={handleTitleChange} className="modalTextarea" />
                                </Col>
                                <Col sm={3}>
                                    <h5>Author</h5>
                                    <textarea rows="1" value={authorChange} onChange={handleAuthorChange} className="modalTextarea" />
                                </Col>
                                <Col sm={3}>
                                    <h5>Language</h5>
                                    <textarea rows="1" value={languageChange} onChange={handleLanguageChange} className="modalTextarea" />
                                </Col>
                                <Col sm={3}>
                                    <h5>Copyright Free</h5>
                                    <input type="checkbox" checked={copyRightFreeStatus} onChange={handleCopyrightChange} />
                                </Col>

                            </Row>
                            <Row className="noMargin">
                                <Col sm={3}>
                                    <h5>Publisher</h5>
                                    <textarea rows="1" value={publisherChange} onChange={handlePublisherChange} className="modalTextarea" />
                                </Col>
                                <Col sm={3}>
                                    <h5>Copyright Year</h5>
                                    <textarea rows="1" value={copyrightYear} onChange={handleCopyrightYear} className="modalTextarea" />
                                </Col>
                                <Col sm={3}>
                                    <h5>Copytight Holder</h5>
                                    <textarea rows="1" value={copyrightHolder} onChange={handleCopyrightHolder} className="modalTextarea" />
                                </Col>
                                <Col sm={3}>
                                    <h5>Publication Year</h5>
                                    <textarea rows="1" value={publicationYear} onChange={handlePublicationYearChange} className="modalTextarea" />
                                </Col>
                            </Row>
                            <Row className="noMargin">
                                <Col sm={3}>
                                    <h5>Keywords (Separate by space)</h5>
                                    <textarea rows="1" value={keywords} onChange={handleKeywordChange} className="modalTextarea" />
                                </Col>
                                <Col sm={3}>
                                    <h5>Mark For Removal</h5>
                                    <input type="checkbox" checked={removalFlag} onChange={handleMarkForRemoval} />
                                </Col>
                                <Col sm={3}>
                                    <h5>Comments</h5>
                                    <textarea rows="1" value={commentTextAreaValue} onChange={handleCommentChange} className="modalTextarea" />
                                </Col>
                            </Row>							


                            <Row className="p-5">
                                <Col sm={4}>
                                    <Button variant="primary" type="submit" onClick={saveReview}>{saveBtnText}</Button>
									{
										context == 'approve' ?
                                        (<Button className="secondary" variant="primary" type="submit" onClick={rejectReview}>Reject</Button>)
                                        : null
									}
                                    <Button className="secondary" variant="secondary" onClick={handleClose}>Cancel</Button>
                                </Col>
                                <Col sm={8}>

                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal.Dialog>
                </div>
            </div>
        </>
    )
}

export default ReviewModel