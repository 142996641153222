import React, { useState, useEffect } from 'react'
import { Row, Col, Tabs, Tab } from 'react-bootstrap'
import ShowSelectedData from '../../components/showSwamijisBook'
import Pagination from '../../components/pagination.js'

const SwamjisBooks = () => {
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(200);
    const [getCurrentData, setCurrentData] = useState([]);
    const [getSearchTotalValue, setSearchTotalValue] = useState({});
    const [getFilterData, setFilterData] = useState([]);
    const [getTermData, setTermData] = useState('All Manuscripts');
    const [getCategoryData, setCategoryData] = useState([]);
    const [getLanguageData, setLanguageData] = useState([]);
    const [getBookSourceData, setBookSourceData] = useState([]);
    const [getCallingComponentGroup, setCallingComponentGroup] = useState([]);

    useEffect(() => {
        var searchFilter = '';
        let language = '';
        let category = '';
        let bookSource = 'SwamijiBooks';
        let callingComponentGroup = 'SwamijiBooks';
        setFilterData(searchFilter);
        setCategoryData(category);
        setLanguageData(language);
        setBookSourceData(bookSource);
        setCallingComponentGroup(callingComponentGroup);
        //fetch('/search/books?filter=' + searchFilter)
        fetch('/search/books/bycategory?category='+ category+'&language='+language+'&bookSource='+bookSource)        
	    .then(response => response.json())
        .then(result => {
            setCurrentData(result.body.hits.hits)
            setSearchTotalValue(result.body.hits.total)
        })
        .catch(error => setLoading(error))

    }, [getTermData])

    // Get Current posts
    const indexOfLastPost = currentPage * postsPerPage; //1*10 = 10
    const indexOfFirstPost = indexOfLastPost - postsPerPage; //10-10 = 0
    const cureentPosts = getCurrentData.slice(indexOfFirstPost, indexOfLastPost)

    // Set Data as per the pagination
    const getDataForPagination = setDataForPagination => setCurrentData(setDataForPagination)

    const showSwamijiBooks = async(key)=>{
        var searchFilter = '';
        let language = '';
        let category = '';
        let bookSource = 'SwamijiBooks';
        let callingComponentGroup = 'SwamijiBooks';

        if (key == 'all') {
            language = '';
            category = '';
        }
        else if (key == 'Other') {
            language = '';
            category = 'Other';
        }
        else {
            category = '';
            language = key;
        }
    
        setFilterData(searchFilter);
        setCategoryData(category);
        setLanguageData(language);
        setBookSourceData(bookSource);
        setCallingComponentGroup(callingComponentGroup);
        fetch('/search/books/bycategory?category='+ category+'&language='+language+'&bookSource='+bookSource)
            .then(response => response.json())
            .then(result => {
                setCurrentData(result.body.hits.hits)
                setSearchTotalValue(result.body.hits.total)
                setFilterData(searchFilter)
            })
            .catch(error => setLoading(error))
    }
    
    return (

        <Row>

            <Tabs defaultActiveKey="all" className="titleBar col-sm-12" onSelect={(k)=>showSwamijiBooks(k)}>
                <Tab eventKey="all" title="All" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />
                </Tab>
                <Tab eventKey="Bengali" title="Bengali" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />
                </Tab>
                <Tab eventKey="Gujarati" title="Gujarati" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />
                </Tab>
                <Tab eventKey="Kannada" title="Kannada" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />
                </Tab>

                <Tab eventKey="Tamil" title="Tamil" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />

                </Tab>
                <Tab eventKey="Telugu" title="Telugu" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />

                </Tab>                

                <Tab eventKey="Chinese" title="Chinese" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />

                </Tab>    

                <Tab eventKey="English" title="English" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />

                </Tab>
                <Tab eventKey="French" title="French" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />

                </Tab> 
                <Tab eventKey="German" title="German" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />

                </Tab>
                <Tab eventKey="Russian" title="Russian" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />

                </Tab>    
                <Tab eventKey="Spanish" title="Spanish" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />
                </Tab>  
                <Tab eventKey="Other" title="Other" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />

                </Tab>                
               
	    {/* <Tab eventKey="all" title="All" className=" col-sm-12">
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} />
                </Tab>
	    */}
            </Tabs>
        </Row>

    )
}
export default SwamjisBooks

