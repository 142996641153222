import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';


import Home from './view/home/home'
import TopNav2 from './view/top-nav2/topnav2'
import TopNav3 from './view/top-nav3/topnav3'
import Scriptures from './view/scriptures/scriptures'
import TopNav5 from './view/top-nav5/topnav5'
import ReviewPage from './view/review-page/review-page'
import AssignReviewPage from './view/assign-review-page/assign-review-page'
import SwamijisBooks from './view/swamijis-books/swamijis-books'
import GurukulBooks from './view/gurukul-books/gurukul-books'
import FourTatvas from './view/four-tatvas/four-tatvas'
import LivingParamAdvaita from './view/living-paramadvita/living-paramadvita'
import LivingEnligtenment from './view/living-enlightenment/living-enlightenment'
import StatesOfConciousness from './view/states-of-conciousness/states-of-conciousness'
import GurukulAcademicBooks from './view/gurukul-academic-books/gurukul-academic-books'
import PowerManifestationVideos from './view/power-manifestation-videos/power-manifestation-videos'
import Chakras from './view/chakras/chakra'
import BhagvatGitaBooks from './view/gita-books/gita-books'
import AvatarComics from './view/avatar-comics/avatar-comics'
import NithyanandaChitraKatha from './view/nithyananda-chitra-katha/nithyananda-chitra-katha'

import SearchResults from './view/search-results/search-results'
import UserLogin from './view/login/login'
import PublicViewPanel from './view/public-view/public-view'
import BookreaderView from './view/bookreader-view/bookreader-view'
import PopularTitlePanel from './view/popular-titles/popular-titles'
import PDFViewer from './view/pdf-viewer/pdf-viewer'



import Notfound from './components/notfound'

const Approuter = () => {
    return (
        
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/r" element={<Home />} />
                <Route path="/r/home" element={<Home />} />
                <Route path="/r/avatar-mission" element={<TopNav2 />} />
                <Route path="/r/ved-agamas" element={<TopNav3 />} />
                <Route path="/r/scriptures" element={<Scriptures />} />
                <Route path="/r/swamijis-books" element={<SwamijisBooks />} />
                <Route path="/r/the-4-tattvas" element={<FourTatvas />} />
                <Route path="/r/living-paramadvaita" element={<LivingParamAdvaita />} />
                <Route path="/r/living-enlightenment" element={<LivingEnligtenment />} />
                <Route path="/r/states-of-conciousness" element={<StatesOfConciousness />} />
                <Route path="/r/guaranteed-solutions" element={<Chakras />} />
                <Route path="/r/bhagavad-gita" element={<BhagvatGitaBooks />} />
                <Route path="/r/avatar-comics" element={<AvatarComics />} />
                <Route path="/r/nithyananda-chitra-katha" element={<NithyanandaChitraKatha />} />
                <Route path="/r/assign-review" element={<AssignReviewPage />} />
                <Route path="/r/popular-titles" element={<PopularTitlePanel />} />
                <Route path="/r/power-manifestation-videos" element={<PowerManifestationVideos />} />
                <Route path="/r/review" element={<ReviewPage />} />
                <Route path="/r/books" element={<TopNav5 />} />
                <Route path="/r/search-results" element={<SearchResults />} />
                <Route path="/r/login" element={<UserLogin />} />
                <Route path="/r/gurukul-academic-books" element={<GurukulAcademicBooks />} />
                <Route path="/r/public/:filterId" element={<PublicViewPanel />} />
                <Route path="/r/bookreader/:filterId" element={<BookreaderView />} />
                <Route path="/r/pdf-reader/:filterId" element={<PDFViewer />} />
                <Route path="*" element={<Notfound />} />
                <Route path="/*" element={<Navigate to="/r" />} />
            </Routes>
        
    );
};


export default Approuter;
