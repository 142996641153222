import React from 'react'
import { Row, Col } from 'react-bootstrap'

const SearchResultPanel = ({ posts, loading }) => {
    if (!loading) {
        return <h2>Loading...</h2>;
    }
	return <div className="rowDataDisplay ">
	{
		posts.map((item, i) => {
			var urlDisplayText = "Read/Download";
			if(item._source.url == "")
				urlDisplayText = "Register to view"
		return (
				<Row key={i}>
					<Col sm={9}>
						<span className="font-weight-bold">Title:</span> {item._source.title}<br />
						<span className="font-weight-bold">Subject:</span> {item._source.subject}<br />
						<span className="font-weight-bold">Script:</span> {item._source.script}<br />
					</Col>
					<Col sm={3}>
					{ 
						(item._source.url === '')
						  ? "Register to view"
						  : <a href={item._source.url} target="_blank">"Read/Download"</a>
					}
						
					</Col>
				</Row>
			
		)
	})
	}
	</div>


}
export default SearchResultPanel;