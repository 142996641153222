import React, { useRef, useState } from 'react'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const Pagination = ({ postsPerPage, totalPosts, getDataForPagination, getFilterData, getBookSourceData, getCategoryData, getLanguageData, getCallingComponentGroup }) => {
    const [loading, setLoading] = useState(true);
    const [activeLeft, setActiveLeft] = useState('hide');
    const pageSize = 10;
    // Get Page Number
    const gotToPage = useRef();

    const getQueryParameter = (qParamName) => {
        var queryParamsList = window.location.search.substring(1);
        var queryParams = queryParamsList.split("&");
        for (var i = 0; i < queryParams.length; i++) {
            var queryParam = queryParams[i].split("=");
            if (queryParam[0] === qParamName) {
                return decodeURI(queryParam[1]);
            }
        }
        return ("All Manuscripts");
    }

    var searchTerm = getQueryParameter('searchterm');
    var searchPaginationCaller = getCallingComponentGroup;
    var searchFilter = getFilterData;
    var searchBookSource = getBookSourceData;
    var searchCategory = getCategoryData;
    var searchLanguage = getLanguageData;
       
    const showPerPagedata = () => {
        let getCurrentPage = gotToPage.current.value
        let getRecordCounter ;
        if (gotToPage.current.value === 1){
            getRecordCounter = Number(gotToPage.current.value)
        }
        else{
            getRecordCounter = (Number(gotToPage.current.value)-1)*pageSize
        }
        let getLastPage = Math.ceil(totalPosts.value / postsPerPage)
        if (getCurrentPage > getLastPage) {
            alert("Page not exist")
        } else {
            if (searchPaginationCaller === "SwamijiBooks") {
                fetch('/search/books/bycategory?category='+ searchCategory+'&language='+searchLanguage+'&bookSource='+searchBookSource+'&recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }else if (searchPaginationCaller === "AllBooks") {
                fetch('/search/books?recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }else if (searchTerm === "All Manuscripts" && searchFilter == '') {
                fetch('/search/all?' + '&recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }
            else if (searchTerm === "All Manuscripts" && searchFilter !== '') {
                fetch('/search/all?filter=' + searchFilter + '&recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }
            else if (searchTerm !== "All Manuscripts" && searchFilter == '') {
                fetch('/search/byTerm/' + searchTerm + '?recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }
            else if (searchTerm !== "All Manuscripts" && searchFilter !== '') {
                fetch('/search/byTerm/' + searchTerm + '?filter=' + searchFilter + '&recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }

        }

    }
    const showFirstPage = () => {
        let getCurrentPage = gotToPage.current.value;
        let getRecordCounter = 0;
        if (getCurrentPage > 1 && getCurrentPage !== 0) {
            if (searchPaginationCaller === "SwamijiBooks") {
                fetch('/search/books/bycategory?category='+ searchCategory+'&language='+searchLanguage+'&bookSource='+searchBookSource+'&recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }else if (searchPaginationCaller === "AllBooks") {
                fetch('/search/books?recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }else if (searchTerm === "All Manuscripts" && searchFilter == '') {
                fetch('/search/all?' + '&recordCounter=0&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }
            else if (searchTerm === "All Manuscripts" && searchFilter !== '') {
                fetch('/search/all?filter=' + searchFilter + '&recordCounter=0&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }
            else if (searchTerm !== "All Manuscripts" && searchFilter == '') {
                fetch('/search/byTerm/' + searchTerm + '?recordCounter=0&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }
            else if (searchTerm !== "All Manuscripts" && searchFilter !== '') {
                fetch('/search/byTerm/' + searchTerm + '?filter=' + searchFilter + '&recordCounter=0&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }

        }
        else {
            alert("No More Pages")
        }

        gotToPage.current.value = 1
    }
    const showLastPage = () => {
        let getCurrentPage = gotToPage.current.value
        let getLastPage = Math.ceil(totalPosts.value / postsPerPage)
        let getRecordCounter = (Number(getLastPage)-1)*pageSize
                
        if (getCurrentPage < getLastPage && getCurrentPage !== 0) {
            if (searchPaginationCaller === "SwamijiBooks") {
                fetch('/search/books/bycategory?category='+ searchCategory+'&language='+searchLanguage+'&bookSource='+searchBookSource+'&recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }else if (searchPaginationCaller === "AllBooks") {
                fetch('/search/books?recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }else if (searchTerm === "All Manuscripts" && searchFilter == '') {
                fetch('/search/all?' + '&recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }
            else if (searchTerm === "All Manuscripts" && searchFilter !== '') {
                fetch('/search/all?filter=' + searchFilter + '&recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }
            else if (searchTerm !== "All Manuscripts" && searchFilter == '') {
                fetch('/search/byTerm/' + searchTerm + '?recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }
            else if (searchTerm !== "All Manuscripts" && searchFilter !== '') {
                fetch('/search/byTerm/' + searchTerm + '?filter=' + searchFilter + '&recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }
        }
        else {
            alert("No More Pages")
        }
        gotToPage.current.value = getLastPage
    }
    const showPreviousPage = () => {
        let getCurrentPage = gotToPage.current.value
        let getRecordCounter ;
        if (getCurrentPage === 1){
            getRecordCounter = getCurrentPage
        }
        else{
            getCurrentPage -= 1;
            getRecordCounter = (Number(getCurrentPage)-1)*pageSize
        }
        if (getCurrentPage >= 1 && getCurrentPage !== 0) {
            getCurrentPage -= 1;
            if (searchPaginationCaller === "SwamijiBooks") {
                fetch('/search/books/bycategory?category='+ searchCategory+'&language='+searchLanguage+'&bookSource='+searchBookSource+'&recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }else if (searchPaginationCaller === "AllBooks") {
                fetch('/search/books?recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }else if (searchTerm === "All Manuscripts" && searchFilter == '') {
                fetch('/search/all?' + '&recordCounter=' + getRecordCounter + '&resultPageSize='+ pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }
            else if (searchTerm === "All Manuscripts" && searchFilter !== '') {
                fetch('/search/all?filter=' + searchFilter + '&recordCounter=' + getRecordCounter + '&resultPageSize='+ pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }
            else if (searchTerm !== "All Manuscripts" && searchFilter == '') {
                fetch('/search/byTerm/' + searchTerm + '?recordCounter=' + getRecordCounter + '&resultPageSize='+ pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }
            else if (searchTerm !== "All Manuscripts" && searchFilter !== '') {
                fetch('/search/byTerm/' + searchTerm + '?filter=' + searchFilter + '&recordCounter=' + getRecordCounter + '&resultPageSize='+ pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }

            gotToPage.current.value -= 1
        }
        else {
            alert("No More Pages")
        }

    }

    const shoNextPage = () => {
        let getCurrentPage = Number(gotToPage.current.value)
        let getRecordCounter ;
        if (gotToPage.current.value === 1){
            getRecordCounter = Number(gotToPage.current.value)
        }
        else{
            getRecordCounter = (Number(gotToPage.current.value))*pageSize
        }
        let getLastPage = Math.ceil(totalPosts.value / postsPerPage)
        if (getCurrentPage != getLastPage) {

            if (searchPaginationCaller === "SwamijiBooks") {
                fetch('/search/books/bycategory?category='+ searchCategory+'&language='+searchLanguage+'&bookSource='+searchBookSource+'&recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }else if (searchPaginationCaller === "AllBooks") {
                fetch('/search/books?recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }else if (searchTerm === "All Manuscripts" && searchFilter == '') {
                fetch('/search/all?' + '&recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }
            else if (searchTerm === "All Manuscripts" && searchFilter !== '') {
                fetch('/search/all?filter=' + searchFilter + '&recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }
            else if (searchTerm !== "All Manuscripts" && searchFilter == '') {
                fetch('/search/byTerm/' + searchTerm + '?recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }
            else if (searchTerm !== "All Manuscripts" && searchFilter !== '') {
                fetch('/search/byTerm/' + searchTerm + '?filter=' + searchFilter + '&recordCounter=' + getRecordCounter + '&resultPageSize=' + pageSize)
                    .then(response => response.json())
                    .then(result => {
                        getDataForPagination(result.body.hits.hits)
                    })
                    .catch(error => setLoading(error))
            }
            gotToPage.current.value = Number(gotToPage.current.value) + 1
        }
        else {
            alert("No More Pages")
        }

    }

    return (
        <>
            <div className="searchPagination fullHeight">
                <span className={activeLeft}><i className="fa fa-angle-double-left " title="First Page" onClick={showFirstPage}></i></span>
                <span><i className="fa fa-angle-left " title="Previous Page" onClick={showPreviousPage}></i></span>
                <span className="text"> | </span>
                <span className="text">Page</span>
                <span><input type="text" ref={gotToPage} className="form-control" defaultValue="1"/></span>
                <span><Button onClick={showPerPagedata}>Go</Button></span>
                <span className="text"> of {Math.ceil(totalPosts.value / postsPerPage)}</span>
                <span className="text"> | </span>
                <span><i className="fa fa-angle-right " title="Next Page" onClick={shoNextPage}></i></span>
                <span><i className="fa fa-angle-double-right " title="Last page" onClick={showLastPage}></i></span>
                {/* <span className="text"> | </span>
                        <span><i className="fa fa-refresh" title="Refresh"></i></span> */}
            </div>

        </>
    )
}
export default Pagination
