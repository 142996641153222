import React, { useState, useEffect  } from 'react'
import {  useParams } from 'react-router-dom'
import { instantiateBookReader } from '../../components/bookreaderInitalizer'
import BookHeaderLogo from '../../components/bookHeaderLogo'
import BackButton from '../../components/back'
import books from '../../assets/dataconfig/homeBooks.json'


const BookreaderView = (props) => {
    const [currentData, setCurrentData] = useState({});
	let { filterId } = useParams();

	const pageUri = (page) => {
		return currentData[page];
	}
    useEffect( async () => {
		console.log("Inside useEffect");
		let book = books[filterId];
        const response = await fetch(book.json);
		const data = await response.json();
		setCurrentData(data);
		instantiateBookReader("#book" , Object.keys(data).length, data);
    }, [])	


	return (
		<div className="book-viewer">
		<div className="book-header">
			<BookHeaderLogo />
			<BackButton bookDownloadUrl={books[filterId].downloadurl}/>
		</div>	
		<div id="book"></div>
		</div>
	)
}

export default BookreaderView