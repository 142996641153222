import React, {Component} from 'react'
import {Modal} from 'react-bootstrap'
import Login from '../../components/login'

const UserLogin = () => {
return (
        <>
            
                <Login />
		
		</>
		)
}

export default UserLogin

