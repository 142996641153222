import React, { useState, useEffect } from 'react'
import { Row, Col, Tabs, Tab } from 'react-bootstrap'
import ShowSelectedData from '../../components/showSelectedData'
import Pagination from '../../components/pagination.js'
import Login from '../../components/login'


const Topnav5 = () => {
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [getCurrentData, setCurrentData] = useState([]);
    const [getSearchTotalValue, setSearchTotalValue] = useState({});
    const [getFilterData, setFilterData] = useState([]);
    const [getTermData, setTermData] = useState('All Manuscripts');

    const [getCategoryData, setCategoryData] = useState([]);
    const [getLanguageData, setLanguageData] = useState([]);
    const [getBookSourceData, setBookSourceData] = useState([]);
    const [getCallingComponentGroup, setCallingComponentGroup] = useState([]);

    useEffect(() => {
        var searchFilter = '';
        let language = '';
        let category = '';
        let bookSource = '';
        let callingComponentGroup = 'AllBooks';
        setFilterData(searchFilter);
        setCategoryData(category);
        setLanguageData(language);
        setBookSourceData(bookSource);
        setCallingComponentGroup(callingComponentGroup);        
	fetch('/search/books?filter=' + searchFilter)
        .then(response => response.json())
        .then(result => {
            setCurrentData(result.body.hits.hits)
            setSearchTotalValue(result.body.hits.total)
        })
        .catch(error => setLoading(error))

    }, [getTermData])

    // Get Current posts
    const indexOfLastPost = currentPage * postsPerPage; //1*10 = 10
    const indexOfFirstPost = indexOfLastPost - postsPerPage; //10-10 = 0
    const cureentPosts = getCurrentData.slice(indexOfFirstPost, indexOfLastPost)

    // Set Data as per the pagination
    const getDataForPagination = setDataForPagination => setCurrentData(setDataForPagination)

    const showPopular = async(key)=>{
        if(key=='popular-titles'){
            var searchFilter = '';
            let language = '';
            let category = '';
            let bookSource = '';
            let callingComponentGroup = 'AllBooks';
            setFilterData(searchFilter);
            setCategoryData(category);
            setLanguageData(language);
            setBookSourceData(bookSource);
            setCallingComponentGroup(callingComponentGroup);            
	    fetch('/search/books?filter=' + searchFilter)
            .then(response => response.json())
            .then(result => {
                setCurrentData(result.body.hits.hits)
                setSearchTotalValue(result.body.hits.total)
                setFilterData(searchFilter)
            })
            .catch(error => setLoading(error))
        }
        else if(key=='all'){
            var searchFilter = '';
            let language = '';
            let category = '';
            let bookSource = '';
            let callingComponentGroup = 'AllBooks';
            setFilterData(searchFilter);
            setCategoryData(category);
            setLanguageData(language);
            setBookSourceData(bookSource);
            setCallingComponentGroup(callingComponentGroup);            
	    fetch('/search/books')
            .then(response => response.json())
            .then(result => {
                setCurrentData(result.body.hits.hits)
                setSearchTotalValue(result.body.hits.total)
                setFilterData(searchFilter)
            })
            .catch(error => setLoading(error))
        }
    }
    let sessionData = sessionStorage.getItem('userData')
    console.log(sessionData);
    return (
        <>
            {
                sessionData == null ?
                    <Login />
                    :
                    <Row>

                        <Tabs defaultActiveKey="popular-titles" className="titleBar col-sm-12" onSelect={(k) => showPopular(k)}>
                            <Tab eventKey="popular-titles" title="Popular Titles" className=" col-sm-12" >
                                <ShowSelectedData posts={cureentPosts} loading={loading} />
                                <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />
                            </Tab>
                        </Tabs>
                    </Row>
            }
        </>
    )
}
export default Topnav5

