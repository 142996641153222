var	imageData;
export function instantiateBookReader(selector, pageCount, data, extraOptions) {
    selector = selector ;
    extraOptions = extraOptions || {};
	imageData = data;
	
	var options = {
        getNumLeafs: function() {
			console.log(pageCount);
			return pageCount;
        },
		
        // Return the width of a given page.  Here we assume all images are 800 pixels wide
        getPageWidth: function(index) {
          return 800;
        },
        // Return the height of a given page.  Here we assume all images are 1200 pixels high
        getPageHeight: function(index) {
          return 1200;
        },

        getPageURI: function(index, reduce, rotate) {
                var t = index + 1;
                var k = "page" + t + ".png"
				console.log(imageData);
                
				return imageData[k];
        },

		// Override the path used to find UI images
		imagesBaseURL: '../BookReader/images/',

		ui: 'full', // embed, full (responsive)

		el: selector,
		showToolbar: false,
		showNavbar: true,
    };
    window.$.extend(options, extraOptions);
    var br = new window.BookReader(options);
    br.init();
}