import React from 'react'
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap'
import {
    FaFacebookSquare,
    FaFacebookF,
    FaInstagram,
    FaTwitter,
    FaYoutube
} from 'react-icons/fa';

export default () => {
    const logouts = () => {
        sessionStorage.removeItem('userData')
        sessionStorage.removeItem('loginTime')
        window.location.reload();
    }
    const logout = (response) => {
        logouts(response, 'google')
    }
    let sessionData = sessionStorage.getItem('userData')
    return (
        <>
            <Row className="mt-5 footer-top-row pt-3 pb-3">
                <Col>
                    <div className="font-devanagari-medium h5">Kailasa’s</div>
                    <div className="font-devanagari-medium">Nithyananda Hindu Digital library</div>
                </Col>
                <Col className="mt-1">
                    World’s Largest Digital collection of
                    Books and Manuscript on Hinduism
                </Col>
                <Col className="mt-1">
                    <a className="text-black" href="mailto:scriptures@nithyanandauniversity.org" >Contact Us</a>
                </Col>
                <Col>
                    <div>Connect us on Social Media</div>
                    <div className="social-media-icons">
                        <a href="https://www.facebook.com/kailasasNHL/" target="_blank"><FaFacebookSquare size="24" /></a>
                        <a href="https://www.instagram.com/kailasas_nhl" target="_blank"><FaInstagram size="24" /></a>
                        <a href="https://twitter.com/kailasahindu" target="_blank"><FaTwitter size="24" /></a>
                        <a href="https://www.youtube.com/channel/UCqMPaDvzQ8Tdckc0e8_LNsQ" target="_blank"><FaYoutube size="24" /></a>
                    </div>
                </Col>
            </Row>
            <Row className="footer-middle-row pt-3 pb-3">
                <Col>
                    <div>Contribute to us</div>
                    <div>by sharing the digital collection</div>
                    <div><Button className="btn-footer-middle-row mt-3"  onClick={(e) => {window.location.href='https://driveuploader.com/upload/mMp4nsZhhD/';}}>Share now</Button></div>
                </Col>
                <Col>
                    <div>Join Us!</div>
                    <div className="text-yellow h5">Become Volunteer</div>
                    <div><Button className="btn-footer-middle-row mt-2"  onClick={(e) => {window.location.href='https://forms.gle/KocGmGekkDNWZ3Y17';}}>Sign Up</Button></div>
                </Col>
                <Col>
                    <a className="text-white" href="https://join.kailaasa.org/" target="_blank">Join Kailasa</a>
                </Col>
                <Col>
                    <div>Visit Other Services</div>
                    <ul>
                        <li><a className="text-yellow link-footer-middle-row" href="https://hinduismpedia.org/wiki/Main_Page" target="_blank">Hinduismpedia</a></li>
                        <li><a className="text-yellow link-footer-middle-row" href="https://driveuploader.com/upload/mMp4nsZhhD/">Donate Books</a></li>
                        <li><a className="text-yellow link-footer-middle-row" href="https://hinduismnow.org/" target="_blank">Hinduism Now</a></li>
                    </ul>
                </Col>
            </Row>
            <Row className="footer-bottom-row pt-3 pb-3">
                <Col sm={2}>
                    <div className="social-media-icons">
                        <a href="https://www.facebook.com/kailasasNHL/" target="_blank"><FaFacebookF size="24" /></a>
                        <a href="https://www.instagram.com/kailasas_nhl" target="_blank"><FaInstagram size="24" /></a>
                        <a href="https://twitter.com/kailasahindu" target="_blank"><FaTwitter size="24" /></a>
                    </div>
                </Col>
                <Col xs lg="2">
                </Col>
                <Col>
                    <div className="text-white">© Copyright 2021. ShriKailasa. All Rights Reserved.</div>
                </Col>
            </Row>
            {sessionData !== null ?
                <div id="googleLogout">
                    <a onClick={logouts} href="#">Logout</a>
                </div>
                : null}

        </>
    )
}
