import React from 'react'

class VideoDisplay extends React.Component{
    render() {
        return (
            <>
                <h2 className="videoTitle">{this.props.title}</h2><br />
                <iframe width="100%" height="200" src={this.props.url} frameBorder="0" allowFullScreen=""></iframe>
            </>
        )
    }
}

export default VideoDisplay