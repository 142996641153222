import React, { Component } from 'react';
import './App.css';

import Header from './containers/header/header'
import Footer from './containers/footer/footer'
import Approuter from './approuter'
import { Container, Row, Col } from 'react-bootstrap'
import ScrollToTop from './components/scrollingWrapper'

export const MyContext = React.createContext();

export default class App extends Component {
  state = {
    userGlobalData: [],
	  idle:false
  }

  saveGlobalData = (data) => {
    this.setState({ userGlobalData: [data] })
  }


  render() {
    return (
      <div className="App">

        <MyContext.Provider value={{ myglobaldata: this.state.userGlobalData, saveData: (data) => this.saveGlobalData(data) }}>
          <Container>

            <Row>
              <Col sm={12}>
                <Header />
              </Col>
            </Row>
            <Row>
              <Col sm={12} className="contentContainer">
                <Approuter />
              </Col>
            </Row>
            <Row>
              <Col sm={12} className="footer">
                <Footer />
              </Col>
            </Row>

          </Container >
          <ScrollToTop />
        </MyContext.Provider>
      </div>
    )
  }
}

