import React from 'react'
import { Row, Col } from 'react-bootstrap'

const showGurukulBook = ({ posts, loading }) => {
    if (!loading) {
        return <h2>Loading...</h2>;
    }
    return <div className="rowDataDisplay ">
        {posts.map((item, i) => {
            return (
                <Row key={i}>
                    <Col sm={3}>
                        <a href={item._source.url} target="_blank">{item._source.title}</a>
                    </Col>

                </Row>
            )
        })
        }
    </div>


}
export default showGurukulBook;
