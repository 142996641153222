import React, { useState, useEffect,useRef  } from 'react'
import { Row, Col, Tabs, Tab } from 'react-bootstrap'
import ShowReviewData from '../../components/showReviewData'
import Pagination from '../../components/pagination.js'
import Login from '../../components/login'
import SubmitReviewPanel from '../../components/submitReviewPanel'
import BootstrapTable from 'fad-react-bootstrap-table-next';
import 'fad-react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Select from 'react-select'
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';


const fieldsToReturn = {
"includes":["type","title","subject","recKey","url","author","abstract", "review_status"]};

const columns = [
{
  dataField: 'recKey',
  text: 'ID'
}, 
{
  dataField: 'title',
  text: 'Title'
}, 
{
  dataField: 'author',
  text: 'Author'
},
{
  dataField: 'subject',
  text: 'Subject'
},
{
  dataField: 'url',
  text: 'Download/Read'
},

];

const columnsAllAssignments = [
{
  dataField: 'recKey',
  text: 'ID'
}, 
{
  dataField: 'title',
  text: 'Title'
}, 
{
  dataField: 'author',
  text: 'Author'
},
{
  dataField: 'subject',
  text: 'Subject'
},
{
  dataField: 'reviewer',
  text: 'Reviewer'
},

{
  dataField: 'url',
  text: 'Download/Read'
}

];

const styles = {
    grid: {
        paddingLeft: 0,
        paddingRight: 0
    },
    row: {
		marginTop:10,
        marginLeft: 0,
        marginRight: 0
    },
    col: {
        paddingLeft: 5,
        marginRight: 5
    }
};



const bookSources = [
  { value: 'bori', label: 'Bori' },
  { value: 'Upen', label: 'Upen' },
  { value: 'archive.org', label: 'Archive' },
  { value: 'ayurveda', label:'Ayurveda'},
  { value: 'SwamijiBooks', label: 'SwamijiBooks' }

]
const emptyReviewData = {
      abstract:"",
      comment:"",
      reviewer:"",
      review_status:"",
      author:"",
      publisher:"",
      copyrightFreeStatus:false,
      publicationYear:"",
      copyrightYear:"",
      categories:[],
      url:"",
      copyrightHolder:"",
      recKey:0,
      subject:"",
      title:"",
      language:""
}	  

const AssignReviewPage = () => {
	const [loading, setLoading] = useState(true);
    const [userList, setUserList] = useState([]);
	const [bookList, setBookList] = useState([]);
	const [bookSource , setBookSource] = useState({ value: 'bori', label: 'Bori' });	
	const [allAssignedReviews, setAllAssignedReviews] = useState([]);
	const [assignedUser , setAssignedUser] = useState(null);
	const [assignedItemsText , setAssignedItemsText] = useState("Select items that need to be assigned");
	const [assignedItemsTextColor , setAssignedItemsTextColor] = useState("black");
	const [submittedReviews , setSubmittedReviews] = useState([]);
	const [selected,setSelected] = useState([])
	const [curSubmittedReviewSelection , setCurSubmittedReviewSelection] = useState({value:emptyReviewData});

	const assignmentTableRef = useRef(null);
	
    const  handleOnSelect = (row, isSelect) => {
         if (isSelect) 
		 {
             let data = selected
             data.push(row)
             setSelected(data)
            
         } 
		 else 
		 {
			 let data = selected
			 data.filter(x => x !== row.recKey)
			 setSelected(data)
         }
		 
       };	
	const  handleOnSelectAll = (isSelect, rows, e) => {
         if (isSelect) 
		 {
             let data = selected
			 
             data.push(...rows)
			 console.log(data);
             setSelected(data)
            
         } 
		 else 
		 {
			 let data = []
			 setSelected(data)
         }		
	};		
	   
	const selectRow = {
	  mode: 'checkbox',
	  clickToSelect: true,
	  classes: 'selection-row',
	  onSelect: handleOnSelect,// on select row  
	  onSelectAll: handleOnSelectAll
	};	
    useEffect( async () => {
		console.log("Inside useEffect");
        const response = await fetch('/review/adminreview/getuserlist');
		const json = await response.json();
		var userList = []
		for ( let i = 0 ; i < json.length ; i++)
		{
			let obj = {}
			obj.value = json[i];
			obj.label = json[i];
			userList.push(obj)
		}
		setUserList(userList);
		
		
    }, [])

	const optionSetValues = async(values) =>{
		setBookSource( values);
		
		
	}
	const onAssigneeCombo = async(values) =>{
		setAssignedUser(values);
		
	}
	const onChangeSubmittedReviews = async(values) =>{
		setCurSubmittedReviewSelection(values);
	}

	const onAssignClick = async() => {
		console.log(selected);
		let req = {};
		req.reviewer = assignedUser.value;
		if(req.reviewer == null || req.reviewer.length == 0)
		{
			setAssignedItemsText("Please select the reviewer" );
			setAssignedItemsTextColor("red");
			return;
		}
		
		req.recKeys = [];
		for(let i = 0 ; i < selected.length ; i++)
			req.recKeys.push(selected[i].recKey);
		console.log(JSON.stringify(req));
		
		if(selected.length > 0)
		{
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(req)
			};		
			const response = await fetch('/review/adminreview/assign', requestOptions);
			const data = await response.json();
			let cnt = selected.length;
			setAssignedItemsText(String(cnt) + " Items are assigned to the user " + assignedUser.value );
			setAssignedItemsTextColor("black");
			setBookList([]);
			setSelected([]);
		}
	}
	const onFetchNext20Click = async() =>{
		console.log('/review/adminreview/fetchnext20?bs=' + bookSource.value);
        const response = await fetch('/review/adminreview/fetchnext20?bs=' + bookSource.value);
		const json = await response.json();
		let newBookList = [...json];
		setBookList(newBookList);
	}

    const fetchRelevantData = async(key)=>{
		if(key=="readyToClose")
		{
			console.log(fetchRelevantData);
			let url = '/review/adminreview/submittedreviews';
			const response = await fetch(url);
			const result = await response.json();
			console.log(result.hits.hits);
			let tmp = [];
			for ( let i = 0 ; i < result.hits.hits.length ; i++)
			{
				let obj = {}
				obj.value = result.hits.hits[i]._source;
				obj.label = String(result.hits.hits[i]._source.recKey);
				tmp.push(obj);
				if( i == 0 )
					setCurSubmittedReviewSelection(obj);
			}
			setSubmittedReviews(tmp);
		}
		else if(key =="allAssignedReviews")
			fetchAllAssignedRecords();
	}
    const fetchAllAssignedRecords = async()=>{

		let url = '/review/adminreview/allassignedreviews';
		const response = await fetch(url);
		const result = await response.json();
		console.log(result.hits.hits);
		let tmp = [];
		for ( let i = 0 ; i < result.hits.hits.length ; i++)
		{
			tmp.push(result.hits.hits[i]._source);
		}
		setAllAssignedReviews(tmp);		

	}
	
	const approveReview = () => {
        let body = {
            "recKey": curSubmittedReviewSelection.value.recKey
        };
        console.log(body);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        };
		let url = '/review/adminreview/acceptreview';
			
        fetch(url  , requestOptions)
        .then(response => {
            if (response.ok) {
				curSubmittedReviewSelection.value.review_status = "Approved";
				setCurSubmittedReviewSelection(curSubmittedReviewSelection);
				
            } else {
                
            }
        }).catch(error => {
            console.log("Errr code" + error);
            
        });
		
	}
	const rejectReview = () => {
	}
	const cancelReview = () => {
	}
	
    let sessionData = sessionStorage.getItem('userData')
    return (
        <>
            {
                sessionData == null ?
                    <Login />
                    :
                    <Row>
                        <Tabs defaultActiveKey="popular-titles" className="titleBar col-sm-12" onSelect={(k)=>fetchRelevantData(k)}>
                            <Tab eventKey="popular-titles" title="New Assignment" className=" col-sm-12" >
								<div class="container" className="p-1">
								  <Row className="align-items-center">
								  <Col className="col-2">Book Source: </Col> 
								  <Col className="col-2">
									<Select value={bookSource} options={bookSources} onChange={optionSetValues}/>
								  </Col>
								  <Col className="col-2">
								      <Button variant="primary" onClick={onFetchNext20Click}>
										Fetch Next 20
									   </Button>
								  </Col>
								  <Col className="col-1">Assign To: </Col> 
								  <Col className="col-3">
									<Select value={assignedUser} options={userList} onChange={onAssigneeCombo}/>
								  </Col>
								  <Col className="col-2">
								      <Button variant="primary" onClick={onAssignClick} >
										Assign
									   </Button>
								  </Col>								  
								</Row>
								</div>
								<div><h3 style={{color:{assignedItemsTextColor}}}>{assignedItemsText}</h3></div>
								<BootstrapTable ref={assignmentTableRef} selectRow={ selectRow } keyField='recKey' data={ bookList } columns={ columns } />
                            </Tab>
							<Tab eventKey="allAssignedReviews" title="Assigned Reviews" className=" col-sm-12" >
								<BootstrapTable keyField='recKey' data={ allAssignedReviews } columns={ columnsAllAssignments } />
							</Tab>
                            <Tab eventKey="readyToClose" title="Submitted Reviews" className=" col-sm-12" >
								<div class="container" className="p-1">
								  <Row className="align-items-center">
								  <Col className="col-1">Record ID: </Col> 
								  <Col className="col-2">
									<Select value={curSubmittedReviewSelection} options={submittedReviews} onChange={onChangeSubmittedReviews}/>
								  </Col>
								  <Col className="col-5">
									Title: {curSubmittedReviewSelection.value.title}
								  </Col>
								  <Col className="col-2"> 
								    Reviewer: {curSubmittedReviewSelection.value.reviewer}
								  </Col> 
								  <Col className="col-2"> 
								    Status: {curSubmittedReviewSelection.value.review_status}
								  </Col> 
								</Row>
								</div>
								<hr  />
								<div class="container" className="p-1">
								<Row>
								
									<Col sm={4} >
										<h5>Selected Categories:</h5>
										<><ul className="selectedItem">{
											curSubmittedReviewSelection.value.categories.map((item, i) =>
												<li key={i} >
													{item}
												</li>)}
										</ul>
										</>
									</Col>
									<Col sm={4}>
										<h5>Abstract</h5>
										<textarea value={curSubmittedReviewSelection.value.abstract} className="abstractTextarea" />
									</Col>
								
								
								</Row>
									<Row className="noMargin">
										<Col sm={3}>
											<h5>Author</h5>
											<textarea rows="1" value={curSubmittedReviewSelection.value.author} className="modalTextarea" />
										</Col>
										<Col sm={3}>
											<h5>Language</h5>
											<textarea rows="1" value={curSubmittedReviewSelection.value.language} />
										</Col>
										<Col sm={3}>
											<h5>Copyright Free</h5>
											<input type="checkbox" checked={curSubmittedReviewSelection.value.copyrightFreeStatus}  />
										</Col>

									</Row>
									<Row className="noMargin">
										<Col sm={3}>
											<h5>Publisher</h5>
											<textarea rows="1" value={curSubmittedReviewSelection.value.publisher} />
										</Col>
										<Col sm={3}>
											<h5>Copyright Year</h5>
											<textarea rows="1" value={curSubmittedReviewSelection.value.copyrightYear}  />
										</Col>
										<Col sm={3}>
											<h5>Copytight Holder</h5>
											<textarea rows="1" value={curSubmittedReviewSelection.value.copyrightHolder}  />
										</Col>
										<Col sm={3}>
											<h5>Publication Year</h5>
											<textarea rows="1" value={curSubmittedReviewSelection.value.publicationYear}  />
										</Col>
									</Row>
									<Row style={styles.row}>
										<Col sm={4}>
											<Button variant="primary" type="submit" onClick={approveReview} style={styles.col}>Approve</Button>
											<Button variant="primary" type="submit" onClick={rejectReview} style={styles.col}>Reject</Button>
										</Col>
										<Col sm={8}>

										</Col>
									</Row>									
								</div>			
                            </Tab>
                        </Tabs>
                    </Row>
					
					
            }
        </>
    )	
}
export default AssignReviewPage

