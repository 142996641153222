import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import NavList from '../../components/navlist'
import TaxonomyNav from '../../assets/dataconfig/scriptnav.json'

import SearchResultPanel from '../../components/searchResultPanel'
import Pagination from '../../components/pagination.js'
import Login from '../../components/login'

const Scriptures = () => {
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [getCurrentData, setCurrentData] = useState([]);
    const [getLeftNavData, setLeftNavData] = useState([]);
    const [getSearchTotalValue, setSearchTotalValue] = useState({});
    const [getFilterData, setFilterData] = useState([]);
    const [getTermData, setTermData] = useState('All Manuscripts');
    const [getSelectedValue, setSelectedValue] = useState('Shruti');
    const [getSelectLeftID, setSelectLeftID] = useState('span_Shruti_0');
    
    // Get Current posts
    const indexOfLastPost = currentPage * postsPerPage; //1*10 = 10
    const indexOfFirstPost = indexOfLastPost - postsPerPage; //10-10 = 0
    const cureentPosts = getCurrentData.slice(indexOfFirstPost, indexOfLastPost)
    
    // Set Data as per the pagination
    const getDataForPagination = setDataForPagination => setCurrentData(setDataForPagination)

    // Set Data as per the Selcted Item
    const getSelectData = (setSelectData) => setSelectedValue(setSelectData)
    const getSelectLeftNavIDFun = (getID) => setSelectLeftID(getID)
    
    useEffect(() => {
        const fetchAllPosts = async () => {
            setFilterData(getSelectedValue)
           // setSelectLeftnav(getSelectLeftNav)
            setLeftNavData(TaxonomyNav.taxonomy)
            if (getSelectedValue !== '') {
                fetch('/search/all?filter=' + getSelectedValue)
                    .then(response => response.json())
                    .then(result => {
                        setCurrentData(result.body.hits.hits)
                        setSearchTotalValue(result.body.hits.total)
                    })
                    .catch(error => setLoading(error))
            }
            
        }
        fetchAllPosts();
        
    }, [getSelectedValue]);

    let sessionData = sessionStorage.getItem('userData')
    
    return (
        <>
        {sessionData == null ? 
            <Login />
            : 
       
        <Row>
            <Col sm={12} className="searchTitleWrap">
                {cureentPosts !=''? 
                    <>Search Results for: {getSelectedValue}</>
                :null}
                {cureentPosts ==''?
                    <>No search results for: {getSelectedValue}</>
                :null}
            </Col>
            <Col sm={3}>
                <NavList leftNav={getLeftNavData} getSelectedData={getSelectData} loading={loading} getSelectLeftNavID ={getSelectLeftNavIDFun} passSelectLeftID={getSelectLeftID}/>
            </Col>
            <Col sm={9}>
                { getSelectedValue !== '' && getSelectedValue != undefined && cureentPosts !='' ? 
                    <>
                        <SearchResultPanel posts={cureentPosts} loading={loading} />
                        <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} />
                    </>
                :null } 
            </Col>
        </Row>
        }
        </>
    )
}
export default Scriptures
