import React, { useEffect,useState } from 'react'
import { Button } from 'react-bootstrap'
import { MyContext } from '../App'

const Search = () => {
    const [getSearchValue, setSearchValue] =useState()


    const searchTerm = "";
    useEffect(()=>{
        //var searchTerm = getSearchTermForQuery('searchterm');
        // console.log("searchTerm==========>" + searchTerm)
        if(searchTerm !=="" && searchTerm !== "All Manuscripts"){
            setSearchValue(searchTerm)
        }
    },[])
    
    
    const submitSearch = (event) => {
        setSearchValue( event.target.value)
        let searchValue = document.getElementById("searchItem");
        searchValue.addEventListener("keyup", function(event) {
            if (event.keyCode === 13) {
            event.preventDefault();
            document.getElementById("searchFilter").click();
            }
        });
    }
    const showSearch=()=>{
        const searchVal = getSearchValue
        if(searchVal!==''){
            window.location.href="/r/search-results?searchterm="+getSearchValue;
        }else{
            alert("Please enter search field")
        }
    }
    
    return (
            <>
                <MyContext.Consumer>
                    {myData => (
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Type to search" id="searchItem" value={getSearchValue || ''}  onChange={(e)=>submitSearch(e)}/>
                            <div className="input-group-append">
                                <Button id="searchFilter" className="btn btn-base" onClick={() => {myData.saveData({ saveSearchValue: getSearchValue }); showSearch();}}><i className="fa fa-search" /> Search </Button>
                            </div>
                        </div>
                    )}
                </MyContext.Consumer>
            </>
        )

}

export default Search
