import React, { useState, useEffect } from 'react'
import { Row, Col, Tabs, Tab } from 'react-bootstrap'
import Pagination from '../../components/pagination.js'

const PowerManifestationVideos = () => {
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(200);
    const [getCurrentData, setCurrentData] = useState([]);
    const [getSearchTotalValue, setSearchTotalValue] = useState({});
    const [getFilterData, setFilterData] = useState([]);
    const [getTermData, setTermData] = useState('All Manuscripts');
    const [getCategoryData, setCategoryData] = useState([]);
    const [getLanguageData, setLanguageData] = useState([]);
    const [getBookSourceData, setBookSourceData] = useState([]);
    const [getCallingComponentGroup, setCallingComponentGroup] = useState([]);

    useEffect(() => {
        var searchFilter = '';
        let language = 'Collective Conciousness';
        let category = '';
        let bookSource = 'LivingEnligtenment';
        let callingComponentGroup = 'LivingEnligtenment';
        setFilterData(searchFilter);
        setCategoryData(category);
        setLanguageData(language);
        setBookSourceData(bookSource);
        setCallingComponentGroup(callingComponentGroup);
        //fetch('/search/books?filter=' + searchFilter)
        fetch('/search/books/bycategory?category=' + category + '&language=' + language + '&bookSource=' + bookSource)
            .then(response => response.json())
            .then(result => {
                setCurrentData(result.body.hits.hits)
                setSearchTotalValue(result.body.hits.total)
            })
            .catch(error => setLoading(error))

    }, [getTermData])

    // Get Current posts
    const indexOfLastPost = currentPage * postsPerPage; //1*10 = 10
    const indexOfFirstPost = indexOfLastPost - postsPerPage; //10-10 = 0
    const cureentPosts = getCurrentData.slice(indexOfFirstPost, indexOfLastPost)

    // Set Data as per the pagination
    const getDataForPagination = setDataForPagination => setCurrentData(setDataForPagination)

    const showSwamijiBooks = async (key) => {
        var searchFilter = '';
        let language = '';
        let category = '';
        let bookSource = 'LivingEnligtenment';
        let callingComponentGroup = 'LivingEnligtenment';

        if (key == 'all') {
            language = '';
            category = '';
        }
        else {
            category = '';
            language = key;
        }

        setFilterData(searchFilter);
        setCategoryData(category);
        setLanguageData(language);
        setBookSourceData(bookSource);
        setCallingComponentGroup(callingComponentGroup);
        fetch('/search/books/bycategory?category=' + category + '&language=' + language + '&bookSource=' + bookSource)
            .then(response => response.json())
            .then(result => {
                setCurrentData(result.body.hits.hits)
                setSearchTotalValue(result.body.hits.total)
                setFilterData(searchFilter)
            })
            .catch(error => setLoading(error))
    }

    return (
        <Row>
            <Tabs defaultActiveKey="Science of Power Manifestation" className="titleBar col-sm-12" >
                <Tab eventKey="Science of Power Manifestation" title="Science of Power Manifestation" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
							<iframe width="560" height="315"
							src="https://www.youtube.com/embed/1tgU86pCZpw">
							</iframe>
						</Col>
					</Row>
                </Tab>
                <Tab eventKey="Body Scan" title="Body Scan" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMZosJxdXpKim7zxREW2dGH_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
                </Tab>
                <Tab eventKey="Remote View" title="Remote View" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMZdqGv22B2V205VHnYTljjp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
                </Tab>				
                <Tab eventKey="Third Eye Reading" title="Third Eye Reading" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMYCbb5c3x6co3Gny8Hbg7WM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>						</Col>
					</Row>
                </Tab>				
                <Tab eventKey="Mind Reading" title="Mind Reading" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMam0DmjVgBOgaih3huGSuO7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
				</Tab>	
                <Tab eventKey="Clairvoyance" title="Clairvoyance" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMYEZa-1-0yWkwzWKD7aWGNe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
				</Tab>	
                <Tab eventKey="Materialization" title="Materialization" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMbydlJLpOaqgqLxUFFrnAp-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
				</Tab>	
                <Tab eventKey="Consciousness Over Matter" title="Consciousness Over Matter" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMbPZE_UEz-wKtVncqVyoWs3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
				</Tab>	
                <Tab eventKey="Power Of Will" title="Power Of Will" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMaWqRTrcuFOcIMajNhWq2M6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
				</Tab>				
                <Tab eventKey="Teleportation" title="Teleportation" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMZCycFSbgrxuQh9sCFaMqgP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
				</Tab>				
                <Tab eventKey="Levitation" title="Levitation" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMbjd6TY5HfShBg5JVmouB1t" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
				</Tab>				
                <Tab eventKey="Length" title="Length" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMbZR1rWsvveovP2bOuvtqmz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
				</Tab>				
                <Tab eventKey="Breadth" title="Breadth" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMa7CY8WXTjv1YeNskcTo-gH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
				</Tab>
                <Tab eventKey="Depth" title="Depth" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMYM-qc_qi7PLD5XZ7bPUtUk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
				</Tab>				
                <Tab eventKey="Ashta Maha Shakti 1" title="Ashta Maha Shakti 1" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMZYNYomS0BrQ83g_F7JklXW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
				</Tab>				
                <Tab eventKey="Ashta Maha Shakti 2" title="Ashta Maha Shakti 2" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMY8OFOhfRwY4kiAUbO5UQeE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
				</Tab>
                <Tab eventKey="Silicon" title="Silicon" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMbjFEZJRYgUVK9se3pzVefk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>						</Col>
					</Row>
				</Tab>
                <Tab eventKey="Akashic Reading" title="Akashic Reading" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMYm6RR_3kUqXso9T3kemPZf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
				</Tab>
                <Tab eventKey="Sarvajnatva" title="Sarvajnatva" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMYneVMPkc_vePyCKvKaSYXx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
				</Tab>
                <Tab eventKey="Nature Powers" title="Nature Powers" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMZUWLWDyiMmoHu9bAKazznw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
				</Tab>
                <Tab eventKey="Special Powers 1" title="Special Powers 1" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMaz3oQnehyU8hnS3eLsG41l" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
				</Tab>
                <Tab eventKey="Special Powers 2" title="Special Powers 2" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMaSj7WyQ436H4MgRMRdN1p_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
				</Tab>	
                <Tab eventKey="Demo Ready" title="Demo Ready" className=" col-sm-12" >
					<Row key="0" style={{"marginTop" : "10px"}}>
					</Row>					
					<Row key="1">
						<Col sm={3}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL87otAmh6TMY3PTqME_BQsGgrJ1ixhgVF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
				</Tab>					
            </Tabs>
        </Row>

    )
}
export default PowerManifestationVideos

