import React, { useState, useEffect  } from 'react'
import {  useParams } from 'react-router-dom'

import { Row, Col, Image } from 'react-bootstrap'
import { SplitPane } from "react-collapse-pane"

import {
    FaFile
} from 'react-icons/fa';

const bookCovers = [
    'https://drive.google.com/uc?export=view&id=1DXpNAMdoCMVVPhB6zJddz6sB6aUFdOwo',
    'https://drive.google.com/uc?export=view&id=1JN6a9XnBZjWpVl6r8Ml8WLK6kG5LR0pr',
    'https://drive.google.com/uc?export=view&id=19_wOgd0mw3fLxHsnJ4-8AgAyqjwlRaCZ',
    'https://drive.google.com/uc?export=view&id=1gnJJkpTY8H-f5b6fDCB4sFS7HRSlS-yE',
    'https://drive.google.com/uc?export=view&id=1RO3Q2KYymiSWx8DBDgMNOnNDmyHOpEIf',
    'https://drive.google.com/uc?export=view&id=16jqV0GKZSbg7-GKPKkoYSAZ2x5KoYyI5'
]

const PopularTitlePanel = () => {
    const [dataset, setDataset] = useState([]);
	const [grid , setGrid] = useState([]);
    const [topics, setTopics] = useState([]);
	const [displayTopics, setDisplayTopics] = useState([]);
	const [topicShowmoreBtnTxt, setTopicShowmoreBtnTxt] = useState("Show More");
	
    const [authors, setAuthors] = useState([]);
    const [displayAuthors, setDisplayAuthors] = useState([]);
	const [authorShowmoreBtnTxt, setAuthorShowmoreBtnTxt] = useState("Show More");

    const [languages, setLanguages] = useState([]);
    const [years, setYears] = useState([]);
	
	const handleBookreaderClick = ( e,col) => {
	}
	
    const handleFilterClick = (filteType, e , item) => {
		console.log(filteType);
    }
	const handleTopicShowMore = () =>{
		if(topicShowmoreBtnTxt == "Show More")
		{
			setDisplayTopics([...topics]);
			setTopicShowmoreBtnTxt("Show Less");
		}
		else
		{
			let dt = []
			for (let i = 0; i < topics.length && i < 10; i++) 
				dt.push(topics[i]);
			setDisplayTopics([...dt]);
			setTopicShowmoreBtnTxt("Show More");

		}
	}
	const handleAuthorShowMore = () =>{
		if(authorShowmoreBtnTxt == "Show More")
		{
			setDisplayAuthors([...authors]);
			setAuthorShowmoreBtnTxt("Show Less");
		}
		else
		{
			let a = []
			for (let i = 0; i < authors.length && i < 10; i++) 
				a.push(authors[i]);
			setDisplayAuthors([...a]);
			setAuthorShowmoreBtnTxt("Show More");

		}
	}	
    useEffect( async () => {
		let url = '/public/popular-titles'
		console.log("Inside useEffect");
        const response = await fetch(url);
		const json = await response.json();
		setDataset(json.hits.hits);
		console.log(json.hits.hits);
		let rowCount = parseInt(json.hits.hits.length / 6);
		if(json.hits.hits.length % 6 > 0)
			rowCount = rowCount + 1;

		let gd = []
		for( let i = 0 ; i < rowCount ; i++)
		{
			let row = [];
			for( let j = 0 ; j < 6 && ((i*6 +j) < json.hits.hits.length) ; j++)
				row.push(json.hits.hits[i*6 + j]);
			gd.push(row)
		}
		setGrid([...gd]);
		
		var tmp = json.aggregations.author.buckets;
		let a = [];
		for (let i = 0; i < tmp.length; i++) 
		{
			let str = tmp[i].key + "(" + tmp[i].doc_count + ")";
			a.push(str)
		}
		a.sort();
		setAuthors([...a]);
		let at = [];
		
		for ( let i = 0; i < a.length && i < 10 ; i++)
		{
			at.push(a[i]);

		}
					
		setDisplayAuthors([...at]);		
		
		tmp = json.aggregations.filterTags.buckets;
		let t = [];
		for (let i = 0; i < tmp.length; i++) 
		{
			let str = tmp[i].key + "(" + tmp[i].doc_count + ")";
			if (str.startsWith("Popular Titles"))
				continue;
			t.push(str)
		}
		t.sort();
		setTopics([...t]);
		let dt = []
		for (let i = 0; i < t.length && i < 10; i++) 
			dt.push(t[i]);
		setDisplayTopics([...dt]);
		
		console.log(t);
    }, {});
	
    return (
<Row>
	<Col sm={2}>
		<Row> 
			<div class="width100">
			Topics
			<hr class="hr-style"></hr>
			</div>
				<ul className="card-links">
				{
					displayTopics.map((item, i) =>
					<li key={i} >
						<a href="#" style={{ "color": "black" }} onClick={(e) => handleFilterClick("topic" ,e , {item})} >{item} </a>				
					</li>)
				}
				{
					topics.length > 10 ?
						<button onClick={() => handleTopicShowMore()}>{topicShowmoreBtnTxt}</button>
					  : null
				}
				</ul>
		</Row>
		<Row> 
			<div class="width100">
			Authors
			<hr class="hr-style"></hr>
			</div>
				<ul className="card-links">
				{
					displayAuthors.map((item, i) =>
					<li key={i} >
						<a href="#" style={{ "color": "black" }} onClick={(e) => handleFilterClick("topic" ,e , {item})} >{item} </a>				
					</li>)
				}
				{
					authors.length > 10 ?
						<button onClick={() => handleAuthorShowMore()}>{authorShowmoreBtnTxt}</button>
					  : null
				}
				</ul>
		</Row>		
	</Col>
	<Col sm={10}>
	    {
			grid.map((cols) => (
				<Row>
				{
					cols.map((col) => (
					<Col >
                        <div><Image src={bookCovers[0]} className="img-fluid img-thumbnail img-cover" /></div>
                        <div className="text-center mt-2"><a href={col._source.title} style={{ "color": "black" }} onClick={(e) => handleBookreaderClick( e,col)} >{col._source.title}</a> </div>
					</Col>
					))
				}
				</Row>
		))
		}

	</Col>
</Row>
    )
}
export default PopularTitlePanel