import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Row, Col, Image, Card, CardGroup, Button } from 'react-bootstrap'
import ShowMoreText from 'react-show-more-text'

import books from '../../assets/dataconfig/homeBooks.json'

import {
    FaFile,
    FaFacebookSquare,
    FaFacebookF,
    FaInstagram,
    FaTwitter,
    FaYoutube
} from 'react-icons/fa';

import VideoDisplay from '../../components/herosection-video.jsx'
import SearchBox from '../../components/searchBox'
import dbdata from '../../assets/dataconfig/taxonomyData.json'
import banner from '../../assets/images/DigiLibrary_Banner_Updated.jpg'
import bookCover from '../../assets/images/epigraphia-indica.jpg'

const bookCovers = [
	'./Signed-Living-Enlightenment.jpg',
	'./autobiography_yogi_book.jpg',
	'./graceful_exit.png',
	'https://drive.google.com/uc?export=view&id=1gnJJkpTY8H-f5b6fDCB4sFS7HRSlS-yE',
	'./tibetan_book_of_dead.png',
	'./swamij_gita.jpg',
    'https://drive.google.com/uc?export=view&id=1DXpNAMdoCMVVPhB6zJddz6sB6aUFdOwo',
    //'https://drive.google.com/uc?export=view&id=1JN6a9XnBZjWpVl6r8Ml8WLK6kG5LR0pr',
    'https://drive.google.com/uc?export=view&id=19_wOgd0mw3fLxHsnJ4-8AgAyqjwlRaCZ',
    'https://drive.google.com/uc?export=view&id=1gnJJkpTY8H-f5b6fDCB4sFS7HRSlS-yE',
    'https://drive.google.com/uc?export=view&id=1RO3Q2KYymiSWx8DBDgMNOnNDmyHOpEIf',
    'https://drive.google.com/uc?export=view&id=16jqV0GKZSbg7-GKPKkoYSAZ2x5KoYyI5'
]

const bookLinks = [
    'https://drive.google.com/file/d/1GiQ0gy9Jg72uYY7illlLejhJfVxEzPAu/view?usp=sharing',
//    '/r/bookreader',
    'https://drive.google.com/file/d/11g19m7tnP65AF2K3J085XNkgqdPCR7hN/view?usp=sharing',
    'https://drive.google.com/file/d/1f4HPU92KnQqqAJPc7HrQOWiD-UceyXmz/view?usp=sharing',
    'https://drive.google.com/file/d/1Mm5equ6AFlC4Eb-gKqK13kOXHXPKE5CZ/view?usp=sharing',
    'https://drive.google.com/file/d/1Yn-wZl2jWBtgiZqHHi8uSA-cCf9kjfdh/view?usp=sharing',
    'https://drive.google.com/file/d/16jqV0GKZSbg7-GKPKkoYSAZ2x5KoYyI5/view?usp=sharing'
]

const bookTitles = ["Living Enlightenment",
    "An Autobiography Of Yogi",
    "Graceful Exit",
    "The Gospel of Sri Ramakrishna",
    "The Tibetan Book of the Dead",
    "Bhagavad Gita Demystified"];

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookReaderShowFlag: false
    };
	this.handleStateChange = this.handleStateChange.bind(this);
  }	

  handleStateChange(show){
    this.setState({ bookReaderShowFlag : show })
  }

    getPageElements = () => {
        let pageElements = [];
        dbdata.taxonomy.map((contents, key) => {
            if (contents.pageIdentifier === "Home") {
                pageElements.push(contents)
            }
        })
        return pageElements
    };

	handleBookreaderClick = (thisPtr , e , inf) => {
		this.setState({ bookReaderShowFlag: true });
		console.log(inf);
		e.preventDefault();
	};
	
    render() {
        return (
            <>
                <Row>
                    <Col className="px-0">
                        <Image src={banner} className="w-100 img-fluid" />
                    </Col>
                </Row>
                <Row className="row-numbers text-center">
                    <Col>
                        <div className="row-numbers-title">1 Million+</div>
                        <div className="row-numbers-subtitle">Books</div>
                    </Col>
                    <Col>
                        <div className="row-numbers-title">5 Million+</div>
                        <div className="row-numbers-subtitle">Catalogue</div>
                    </Col>
                    <Col>
                        <div className="row-numbers-title">50,000+</div>
                        <div className="row-numbers-subtitle">Manuscripts</div>
                    </Col>
                    <Col>
                        <div className="row-numbers-title">10000+ Full</div>
                        <div className="row-numbers-subtitle">View books</div>
                    </Col>
                    <Col>
                        <div className="row-numbers-title">20000+ Full</div>
                        <div className="row-numbers-subtitle">View manuscripts</div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col xs lg="3">
                    </Col>
                    <Col>
                        <SearchBox />
                    </Col>
                    <Col xs lg="3">
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <div className="font-devanagari-medium content-title h5">Welcome to Kailasa’s Nithyananda Hindu Digital library</div>
                        <div className="font-devanagari-regular text-content">Kailaasa's Nithyananda Hindu Library is the world’s largest knowledge centre of source scriptures, manuscripts and books on Hinduism – dedicated to Locate, Acquire, Collect, Organize,
                            Preserve, Spread the Veda-Agamas and sharing 20 million Hindu texts. Kailaasa's Nithyananda Hindu Library provides Source Knowledge to bring spiritual legitimacy, religious
                            legitimacy, social legitimacy, cultural legitimacy, historic legitimacy, economical legitimacy and political legitimacy to Sanatana Hindu Dharma.</div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <CardGroup>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Who is the Avatar?</Card.Title>
                                    <ShowMoreText lines={8} more='Show more' less='Show less' anchorClass="link-view-more">
                                        Supreme Pontiff of Hinduism (SPH), His Divine Holiness (HDH) Sri Nithyananda Paramashivam is the reviver of KAILASA - the ancient, enlightened, Hindu civilizational nation. SPH is an Avatar/ Jagatguru Mahasannidhanam (JGM) of Hinduism.SPH has made power manifestation into a science.The SOVEREIGN ORDER OF KAILASA led by SPH & NITHYANANDA ORDER of monks, nuns & Hindu diaspora are working for global peace & to give super conscious breakthrough to humanity. 
									</ShowMoreText>
                                </Card.Body>

                            </Card>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Browse by Collection</Card.Title>
                                    <Card.Text>
                                        <ul className="card-links">
                                            <li><FaFile /><a href="/r/public/manuscript" style={{ "color": "black" }}>Manuscripts</a> </li>
                                            <li><FaFile /><a href="/r/public/books" style={{ "color": "black" }}>BOOKs</a> </li>
                                            <li><FaFile /><a href="https://hinduismpedia.kailaasa.org/wiki/Gita_Verse_1.1" target="_blank" style={{ "color": "black" }}>e-Text</a> </li>
                                            <li><FaFile /><a href="/r/swamijis-books" style={{ "color": "black" }}>SPH Collection</a></li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Gurukul Books And Videos</Card.Title>
                                    <Card.Text>
										<table>
										<tr>
										<td>
                                        <ul className="card-links">
                                            <li><FaFile /><a href="/r/power-manifestation-videos" style={{ "color": "black" }}>Power Manifestation Videos</a> </li>
                                            <li><FaFile /><a href="/r/guaranteed-solutions" style={{ "color": "black" }}>Guaranteed Solutions</a></li>
                                            <li><FaFile /><a href="/r/nithyananda-chitra-katha" style={{ "color": "black" }}>Nithyananda Chitra Katha</a> </li>
                                            <li><FaFile /><a href="/r/living-enlightenment" style={{ "color": "black" }}>Living Enlightenment</a> </li>
                                            <li><FaFile /><a href="/r/gurukul-academic-books" style={{ "color": "black" }}>Academic Books</a> </li>
                                        </ul>
										</td>
										
                                        <ul className="card-links" style={{ "marginLeft": "10px" }}>
                                            <li><FaFile /><a href="/r/bhagavad-gita" style={{ "color": "black" }}>Bhagavad Gita</a></li>
                                            <li><FaFile /><a href="/r/avatar-comics" style={{ "color": "black" }}>Avatar Comics</a></li>
                                            <li><FaFile /><a href="/r/the-4-tattvas" style={{ "color": "black" }}>The 4 Tattvas</a> </li>
                                            <li><FaFile /><a href="/r/living-paramadvaita" style={{ "color": "black" }}>Living Paramadvaita</a> </li>
                                            <li><FaFile /><a href="/r/states-of-conciousness" style={{ "color": "black" }}>States Of Conciousness</a> </li>

                                        </ul>
										
										</tr>
										</table>

                                    </Card.Text>
                                </Card.Body>

                            </Card>
                        </CardGroup>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <div className="font-devanagari-medium content-title h5">Featuered Books</div>
                    </Col>
                </Row>
                <Row className="mt-4">
					{
					Object.keys(books).map((item,index) => {
						return(
						<Col>
							<div><Image src={books[item].thumbnail}className="img-fluid img-thumbnail img-cover" /> </div>
							<div className="text-center mt-2"><a href={books[item].type=="pdf"? "r/pdf-reader/"+item : "r/bookreader/"+item} style={{ "color": "black" }}  >{books[item].title}</a> </div>
						</Col>)
						})
					}  
                </Row>
            </>
        )
    }
}