import React, { useState, useEffect  } from 'react'
import {  useParams } from 'react-router-dom'

import { Row, Col } from 'react-bootstrap'
import NavList from '../../components/navlist'
import TaxonomyNav from '../../assets/dataconfig/scriptnav.json'

import SearchResultPanel from '../../components/searchResultPanel'
import Pagination from '../../components/pagination.js'
import Login from '../../components/login'

const PublicViewPanel = () => {
    const [loading, setLoading] = useState(true);
    const [getCurrentData, setCurrentData] = useState([]);
	let { filterId } = useParams();
	console.log(filterId);
    useEffect(() => {
		
		let fetchUrl = "/public/"+filterId;
        const fetchAllPosts = async () => {
                fetch(fetchUrl)
                    .then(response => response.json())
                    .then(result => {
						console.log(result);
                        setCurrentData(result.hits.hits);
						//console.log(result.body.hits.hits.length);
                        //setSearchTotalValue(result.body.hits.total)
                    })
                    .catch(error => setLoading(error))
        }
        fetchAllPosts();
        
    }, []);

    return (
        <Row>
			<SearchResultPanel posts={getCurrentData} loading={loading} />
        </Row>
    )
}
export default PublicViewPanel