import React, { useState, useEffect } from 'react'
import { Row, Col, Tabs, Tab } from 'react-bootstrap'
import ShowSelectedData from '../../components/showGurukulBook'
import Pagination from '../../components/pagination.js'


const FourTatvas = () => {
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(200);
    const [getCurrentData, setCurrentData] = useState([]);
    const [getSearchTotalValue, setSearchTotalValue] = useState({});
    const [getFilterData, setFilterData] = useState([]);
    const [getTermData, setTermData] = useState('All Manuscripts');
    const [getCategoryData, setCategoryData] = useState([]);
    const [getLanguageData, setLanguageData] = useState([]);
    const [getBookSourceData, setBookSourceData] = useState([]);
    const [getCallingComponentGroup, setCallingComponentGroup] = useState([]);

    useEffect(() => {
        var searchFilter = '';
        let language = 'Authenticity';
        let category = '';
        let bookSource = 'FourTatvas';
        let callingComponentGroup = 'FourTatvas';
        setFilterData(searchFilter);
        setCategoryData(category);
        setLanguageData(language);
        setBookSourceData(bookSource);
        setCallingComponentGroup(callingComponentGroup);
        //fetch('/search/books?filter=' + searchFilter)
        fetch('/search/books/bycategory?category=' + category + '&language=' + language + '&bookSource=' + bookSource)
            .then(response => response.json())
            .then(result => {
                setCurrentData(result.body.hits.hits)
                setSearchTotalValue(result.body.hits.total)
            })
            .catch(error => setLoading(error))

    }, [getTermData])

    // Get Current posts
    const indexOfLastPost = currentPage * postsPerPage; //1*10 = 10
    const indexOfFirstPost = indexOfLastPost - postsPerPage; //10-10 = 0
    const cureentPosts = getCurrentData.slice(indexOfFirstPost, indexOfLastPost)

    // Set Data as per the pagination
    const getDataForPagination = setDataForPagination => setCurrentData(setDataForPagination)

    const showSwamijiBooks = async (key) => {
        var searchFilter = '';
        let language = '';
        let category = '';
        let bookSource = 'FourTatvas';
        let callingComponentGroup = 'FourTatvas';

        if (key == 'all') {
            language = '';
            category = '';
        }
        else {
            category = '';
            language = key;
        }

        setFilterData(searchFilter);
        setCategoryData(category);
        setLanguageData(language);
        setBookSourceData(bookSource);
        setCallingComponentGroup(callingComponentGroup);
        fetch('/search/books/bycategory?category=' + category + '&language=' + language + '&bookSource=' + bookSource)
            .then(response => response.json())
            .then(result => {
                setCurrentData(result.body.hits.hits)
                setSearchTotalValue(result.body.hits.total)
                setFilterData(searchFilter)
            })
            .catch(error => setLoading(error))
    }

    return (

        <Row>

            <Tabs defaultActiveKey="Authenticity" className="titleBar col-sm-12" onSelect={(k) => showSwamijiBooks(k)}>

                <Tab eventKey="Authenticity" title="Authenticity" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />
                </Tab>
                <Tab eventKey="Completion" title="Completion" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />
                </Tab>
                <Tab eventKey="Causing" title="Causing" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />
                </Tab>
                <Tab eventKey="Enriching" title="Enriching" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />
                </Tab>
                <Tab eventKey="Integrity" title="Integrity" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />
                </Tab>
                <Tab eventKey="Listening" title="Listening" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />
                </Tab>				
                <Tab eventKey="Responsibility" title="Responsibility" className=" col-sm-12" >
                    <ShowSelectedData posts={cureentPosts} loading={loading} />
                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination={getDataForPagination} getFilterData={getFilterData} getBookSourceData={getBookSourceData} getCategoryData={getCategoryData} getLanguageData={getLanguageData} getCallingComponentGroup={getCallingComponentGroup} />
                </Tab>
          </Tabs>
        </Row>

    )
}
export default FourTatvas

