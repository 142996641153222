import React, { useState, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'

import SearchResultTitle from '../../components/searchResultTitle'
import SearchResultNav from '../../components/searchResultNav'
import SearchResultPanel from '../../components/searchResultPanel'
import Pagination from '../../components/pagination.js'
//import {getSearchTermForQuery,getSearchTermForDisplay,getSearchFilterParams} from '../../lib/lms/utils.js'
import Login from '../../components/login'

const getSearchTermForQuery = (t) => {
    return t;
}
const getSearchFilterParams = (t) => {
    return t;
}

const getSearchTermForDisplay = (t) => {
    return t;
}
const SearchResult = () => {
   
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [getCurrentData, setCurrentData] = useState([]);
    const [getLeftNavData, setLeftNavData] = useState([]);
    const [getSearchTotalValue, setSearchTotalValue] = useState({});
    const [getFilterData, setFilterData] = useState([]);
    const [getTermData, setTermData] = useState('All Manuscripts');

    useEffect(() => {
        var searchTerm = getSearchTermForQuery('searchterm');
        var searchFilter = getSearchFilterParams();
       // console.log("searchTerm==========>" + searchTerm)
        const fetchAllPosts = async () => {
            if(searchTerm === "All Manuscripts" && searchFilter === ''){
                fetch('/search/all')
                    .then(response => response.json())
                    .then(result => {
                        setCurrentData(result.body.hits.hits)
                        setLeftNavData(result.body.aggregations.filterCategories.buckets)
                        setSearchTotalValue(result.body.hits.total)
                    })
                    .catch(error => setLoading(error))
            }
            else if(searchTerm !== "All Manuscripts" && searchFilter === ''){
                fetch('/search/byTerm/' + searchTerm)
                    .then(response => response.json())
                    .then(result => {
                        setCurrentData(result.body.hits.hits)
                        setLeftNavData(result.body.aggregations.filterCategories.buckets)
                        setSearchTotalValue(result.body.hits.total)
                        setTermData(searchTerm)
                    })
                    .catch(error => setLoading(error))
            }
        }
        fetchAllPosts();
        

    }, []);

    // Get Current posts
    const indexOfLastPost = currentPage * postsPerPage; //1*10 = 10
    const indexOfFirstPost = indexOfLastPost - postsPerPage; //10-10 = 0
    const cureentPosts = getCurrentData.slice(indexOfFirstPost, indexOfLastPost)
   
   // change page
 //   const paginate = pageNumber => setCurrentPage(pageNumber)

    // Set Data as per the pagination
    const getDataForPagination = setDataForPagination => setCurrentData(setDataForPagination)
 
    // Apply Button Click Filter
    const refreshResults = () => {
        var searchTerm = getSearchTermForQuery('searchterm');
        var searchFilter = getSearchFilterParams();
        setFilterData(searchFilter)
       // setTermData(searchTerm)
     
        if(searchTerm === "All Manuscripts" && searchFilter !== ''){
            document.getElementById("enteredSearchterm").style.display = "none";
            document.getElementById("filterShow").style.display = "block";
            document.getElementById("selectedSearchFilter").innerHTML = searchFilter;
            fetch('/search/all?filter=' + searchFilter)
            .then(response => response.json())
            .then(result => {
                setCurrentData(result.body.hits.hits)
                setLeftNavData(result.body.aggregations.filterCategories.buckets)
                setSearchTotalValue(result.body.hits.total)
            })
            .catch(error => setLoading(error))
            return (true);
        }
        else if(searchTerm !== "All Manuscripts" && searchFilter !== ''){
            document.getElementById("enteredSearchterm").style.display = "none";
            document.getElementById("filterShow").style.display = "block";
            document.getElementById("selectedSearchFilter").innerHTML = searchFilter;
            fetch('/search/byTerm/' + searchTerm + '?filter=' + searchFilter)
            .then(response => response.json())
            .then(result => {
                setCurrentData(result.body.hits.hits)
                setLeftNavData(result.body.aggregations.filterCategories.buckets)
                setSearchTotalValue(result.body.hits.total)
            })
            .catch(error => setLoading(error))
            return (true);
        }
        else {
            alert("Please select filter from Left Navigation Options")
        }
        
    }
    const pageReload=()=>{
        window.location='/search-results';
    }
    const clearFilter=()=>{
        var searchTerm = getSearchTermForQuery('searchterm');
        var searchFilter = '';
        setFilterData(searchFilter)
        if(searchTerm === "All Manuscripts" && searchFilter === ''){
            document.getElementById("enteredSearchterm").style.display = "inline-block";
            document.getElementById("filterShow").style.display = "none";
            fetch('/search/all')
            .then(response => response.json())
            .then(result => {
                setCurrentData(result.body.hits.hits)
                setLeftNavData(result.body.aggregations.filterCategories.buckets)
                setSearchTotalValue(result.body.hits.total)
            })
            .catch(error => setLoading(error))
            return (true);
        }
        else if(searchTerm !== "All Manuscripts" && searchFilter === ''){
            document.getElementById("enteredSearchterm").style.display = "inline-block";
            document.getElementById("filterShow").style.display = "none";
            fetch('/search/byTerm/' + searchTerm)
            .then(response => response.json())
            .then(result => {
                setCurrentData(result.body.hits.hits)
                setLeftNavData(result.body.aggregations.filterCategories.buckets)
                setSearchTotalValue(result.body.hits.total)
                setTermData(searchTerm)
            })
            .catch(error => setLoading(error))
            return (true);
        }
        else {
            alert("Please select filter from Left Navigation Options")
        }
    }
    let sessionData = sessionStorage.getItem('userData')
    return (
        <>
            {
                <>
                    {getSearchTotalValue.value == 0 && getTermData !='All Manuscripts'?
                        <Row>
                            <Col sm={12}>
                                <span className="searchTitleWrap"> No Results found for: {getTermData} </span><br /><br />
                                {getFilterData !='' ? 
                                    <>
                                        <span id="enteredSearchterm"></span>
                                        <span id="filterShow"></span>
                                        <span className="searchTitleWrap"> No Results found for Filter: {getFilterData}</span><br /><br />
                                        <Button onClick={clearFilter} className="button">Clear Filter</Button>
                                    </>
                                :
                                <Button onClick={pageReload} className="button">Show All Manuscripts/Books</Button>}
                            </Col>
                        </Row>
                    : null}
                    
                    {getSearchTotalValue.value != 0 || getTermData =='All Manuscripts'?
                        <><Row>
                            <Col sm={12} className="searchTitleWrap">
                                <SearchResultTitle showTitle={getSearchTotalValue} loading={loading}/> : 
                                <span className="searchTitle">
                                    <span id="enteredSearchterm"> {getSearchTermForDisplay('searchterm')} </span>
                                    <span id="filterShow">Showing results filtered by " <span id="selectedSearchFilter"></span> " for search term : "{getSearchTermForDisplay('searchterm')}"</span>
                                </span>
                            </Col>
                            <Col sm={12} className="applyFilter">
                                {getSearchTotalValue.value !==0 ? 
                                    <Button onClick={refreshResults} className="button"> Apply Filter</Button>
                                :null}
                                {getSearchTotalValue.value ==0 && getFilterData ==""? 
                                    <Button onClick={pageReload} className="button">Show All Manuscripts/Books</Button>
                                :null}
                                {getFilterData !=""? 
                                    <Button onClick={clearFilter} className="button">Clear Filter</Button>
                                :null}
                            </Col>
                            
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <SearchResultNav leftNav={getLeftNavData} loading={loading} getFilterData={getFilterData}/>
                            </Col>
                            {getSearchTotalValue.value !== 0 ?
                                <Col sm={9}>
                                    <SearchResultPanel posts={cureentPosts} loading={loading} />
                                    <Pagination postsPerPage={postsPerPage} totalPosts={getSearchTotalValue} getDataForPagination ={getDataForPagination} getFilterData={getFilterData} />
                                </Col>
                            :null}
                        </Row></>
                    :null}
                </>
            }
            </>
    )
}
export default SearchResult