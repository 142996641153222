import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = ({ bookDownloadUrl }) => {
  const navigate = useNavigate();

  const onBack = (e) => {
    e.preventDefault();
    navigate(-1); // This goes back to the previous page
  };

  return (
    <div style={{ marginRight: 20 }} className="back-btn">
      <a style={{ color: "white", marginRight: 20 }} href={bookDownloadUrl} target="_blank" rel="noopener noreferrer">
        Download
      </a>
      <a style={{ color: "white" }} href="#" onClick={onBack}>
        &#60; Back
      </a>
    </div>
  );
};

export default BackButton;