import React, { useState, useEffect, useRef } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap'


const Login = () => {
    const [userEmail, updateEmail] = useState([]);
    const [userPassword, updatePassword] = useState([]);
    const [msg, updateMsg] = useState("Please Provide Login Credentials");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [sessionStore, setsession] = useState(null)
    const [loading, setLoading] = useState(true);

    useEffect(function () {
    }, [])

    function doLogin(event) {
        event.preventDefault();
		
        let useRemoteAPI = "true";
        if(useRemoteAPI == "true") {
            fetch('/auth/admin',
            {
                method: 'POST',
                body:"",
                headers: 
                {
                    "Content-Type": "application/json; charset=utf-8",
                    "userid": btoa(email),
                    "password":btoa(password)
                }
            }).then(response => {
                if (response.ok) {
                    response.json().then(obj => {
                        console.log(obj);
                        sessionStorage.setItem('user', JSON.stringify(email))
                        sessionStorage.setItem('userData', obj)
                        sessionStorage.setItem('loginTime', Date())
                        setsession(sessionStorage.getItem('userData'))
                        window.location.assign('/r/home');
                    });
                }else{
                    updateMsg("Please use correct Credential!!")
                }
            }).catch(error => {
                console.log("Errr code" +error);
            });
        }
        else {
            const filterEmail = userEmail.includes(email)
            const filterPassword = userPassword.includes(password)
            if (filterEmail == true &&  filterPassword == true) {
                sessionStorage.setItem('userData',JSON.stringify(email))
                sessionStorage.setItem('loginTime',Date())
                setsession(sessionStorage.getItem('userData'))
                window.location.reload();
            }
            else {
                updateMsg("Please use correct Credential!!")
            }
        }        
    }
    const sessionData = sessionStorage.getItem('userData') 

    const handleClose = () => {
        window.location.href= '/'
    }

    return (
        <>
            
            {sessionData === "null" || sessionData === null ?
            <div id="modalOpen" className="modal-open">
                <div className="fade modal-backdrop show"></div>
                <div className="fade modal show">
                    <Modal.Dialog>
                        <Modal.Header >
                            <Modal.Title>{msg}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Row>
                                <Col sm={12} className="closeIcon">
                                    <i onClick={handleClose} className="fa fa-close" />
                                </Col>
                            </Row>
		    	            <Row className="form-group">
                                <Col sm={4} className="text-right">Email address</Col>
                                <Col sm={8}><input type="email" className="form-control" placeholder="Enter email" autoFocus value={email} onChange={e => setEmail(e.target.value)} /></Col>
                            </Row>
                            <Row className="form-group">
                                <Col sm={4} className="text-right">Password</Col>
                                <Col sm={8}><input type="password" className="form-control" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} /></Col>
                            </Row>
                            <Row className="form-group">
                                <Col sm={4}></Col>
                                <Col sm={8}>
		    			        <Button variant="primary" type="submit" onClick={doLogin}>Login</Button>
		    			        <Button className="secondary" variant="secondary" onClick={handleClose}>Close</Button>
		    		            </Col>
                            </Row>
                            <Row>
                                <Col sm={12} className="modalMessage">
                                        To Gain Access <a href="mailto: sri.ramanatha@nithyananda.org">Send Email</a>
                                </Col>
                            </Row>

                        </Modal.Body>
                    </Modal.Dialog>
                </div>
            </div>
             : null}
        </>
    )
}

export default Login




