import React from 'react'

const SearchResultNav = ({ leftNav, loading, getFilterData }) => {
    var searchFilter = getFilterData;
    const checkAll=()=>{
        var getVal = document.getElementsByClassName('checkbox');
        for (var i = 0; i < getVal.length; i++) {
            var currentVal = getVal[i].value;
                if(searchFilter == currentVal){
                    getVal[i].checked = true
                }
                else{
                    getVal[i].checked = false
                }
        }
    }
    checkAll();
 
    if (!loading) {
        return <h2>Loading...</h2>;
    }
    else if(getFilterData != ""){
        return <ul className="taxonomyMenu maxHeight searchResult">
            {leftNav.map((item, i) => {
                return (
                    <li key={i}>
                        <input type="checkbox"  className="checkbox" value={item.key} /> {item.key} ({item.doc_count}) 
                    </li>
                        
                )
            })
            }
        </ul> 
    }
    else {
        return <ul className="taxonomyMenu maxHeight searchResult">
            {leftNav.map((item, i) => {
                return (
                    <li key={i}>
                        <input type="checkbox"  className="checkbox" value={item.key} /> {item.key} ({item.doc_count})
                    </li>
                        
                )
            })
            }
        </ul>
    }
}
export default SearchResultNav