import React from 'react'
import { Row, Col } from 'react-bootstrap'

const showSwamijisBook = ({ posts, loading }) => {
    if (!loading) {
        return <h2>Loading...</h2>;
    }
    return <div className="rowDataDisplay ">
        {posts.map((item, i) => {
            return (
                <Row key={i}>
                    <Col sm={1}>
                        <span><img src={item._source.icon} alt="Image Coming Soon" width="100" height="100" onerror="this.onerror=null;this.src='https://drive.google.com/uc?export=view&id=1aZW_WrEsfrws7mUsk7W0MSp5EqXS28dy';"></img></span><br/>
                    </Col>
                    <Col sm={3}>
                        <span className="font-weight-bold">Title:</span> {item._source.title}<br />
                        <span className="font-weight-bold">Language:</span> {item._source.language}<br />
                    </Col>

                    <Col sm={6}>
                        <span className="font-weight-bold">Abstract:</span> <span className="overFlowtexts">{item._source.abstract}</span>
                    </Col>
                    <Col sm={2}>
                        <a href={item._source.url}>Download</a>
                    </Col>
                </Row>
            )
        })
        }
    </div>


}
export default showSwamijisBook;
