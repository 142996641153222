import React, { useState, useEffect } from 'react'
import { Row, Col, Tabs, Tab } from 'react-bootstrap'
import ShowReviewData from '../../components/showReviewData'
import Pagination from '../../components/pagination.js'
import Login from '../../components/login'
import Select from 'react-select'
import SubmitReviewPanel from '../../components/submitReviewPanel'

const emptyReviewData = {
      abstract:"",
      comment:"",
      reviewer:"",
      review_status:"",
      author:"",
      publisher:"",
      copyrightFreeStatus:false,
      publicationYear:"",
      copyrightYear:"",
      categories:[],
      url:"",
      copyrightHolder:"",
      recKey:0,
      subject:"",
      title:"",
      language:"",
	  tags:""
}


const ReviewPage = () => {
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(20);
    const [getCurrentData, setCurrentData] = useState([]);
	const [assignedReviews , setAssignedReviews] = useState([]);
	const [curAssignedReview , setCurAssignedReview] = useState({value:emptyReviewData});
    const [getSearchTotalValue, setSearchTotalValue] = useState({});
    const [getFilterData, setFilterData] = useState([]);
    const [getTermData, setTermData] = useState('All Manuscripts');

    const [getCategoryData, setCategoryData] = useState([]);
    const [getLanguageData, setLanguageData] = useState([]);
    const [getBookSourceData, setBookSourceData] = useState([]);
    const [getCallingComponentGroup, setCallingComponentGroup] = useState([]);
	
	const initializeReviewData = (data) =>{
		let obj = {};
		obj.abstract = data.abstract??"";
		obj.comment = data.comment??"";
		obj.reviewer = data.reviewer??"";
		obj.author = data.author??"";
		obj.publisher = data.publisher??"";
		obj.copyrightFreeStatus = data.copyrightFreeStatus??false;
		obj.publicationYear = data.publicationYear??"";
		obj.copyrightYear = data.copyrightYear??"";
		obj.categories = data.categories??[];
		obj.url = data.url??"";
		obj.copyrightHolder = data.copyrightHolder??"";
		obj.recKey = data.recKey;
		obj.subject = data.subject??"";
		obj.title = data.title??"";
		obj.language = data.language??"";
		obj.tags =data.tags??"";
		return obj;
		
	}

    useEffect(() => {
		let user = sessionStorage.getItem('user')
		let url = '/review/adminreview/assignedreviews?email=' + user;
		url = url.replaceAll('"' , '');
		console.log(url);
		
        var searchFilter = '';
        let language = '';
        let category = '';
        let bookSource = 'SwamijiBooks';
        let callingComponentGroup = 'AllBooks';
        setFilterData(searchFilter);
        setCategoryData(category);
        setLanguageData(language);
        setBookSourceData(bookSource);
        setCallingComponentGroup(callingComponentGroup);        
        fetch(url )
        .then(response => response.json())
        .then(result => {
			console.log(result);
            setCurrentData(result.hits.hits)
            setSearchTotalValue(result.hits.total)
			let tmp = [];
			for ( let i = 0 ; i < result.hits.hits.length ; i++)
			{
				let obj = {}
				obj.value = initializeReviewData(result.hits.hits[i]._source);
				obj.label = String(result.hits.hits[i]._source.recKey);
				tmp.push(obj);
				if( i == 0 )
					setCurAssignedReview(obj);
			}
			setAssignedReviews(tmp);			
        })
        .catch(error => setLoading(error))

    }, [getTermData])

    // Get Current posts
    const indexOfLastPost = currentPage * postsPerPage; //1*10 = 10
    const indexOfFirstPost = indexOfLastPost - postsPerPage; //10-10 = 0
    const cureentPosts = getCurrentData.slice(indexOfFirstPost, indexOfLastPost)

    // Set Data as per the pagination
    const getDataForPagination = setDataForPagination => setCurrentData(setDataForPagination)

    const onChangeAssignedReviews = async(values) =>{
		setCurAssignedReview(values);
	}
	let sessionData = sessionStorage.getItem('userData')
	
    return (
        <>
            {
                sessionData == null ?
                    <Login />
                    :
                    <Row>

                        <Tabs defaultActiveKey="popular-titles" className="titleBar col-sm-12" >
                            <Tab eventKey="popular-titles" title="Assigned Reviews" className=" col-sm-12" >
                                <ShowReviewData posts={cureentPosts} loading={loading} curContext="assign"/>
                            </Tab>
						
                        </Tabs>
                    </Row>
            }
        </>
    )
}
export default ReviewPage

