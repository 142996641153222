import React, { useState, useEffect  } from 'react'
import {  useParams } from 'react-router-dom'
import BookHeaderLogo from '../../components/bookHeaderLogo'
import BackButton from '../../components/back'
import { Document,Page } from 'react-pdf';

import books from '../../assets/dataconfig/homeBooks.json';


//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.js`;

const PDFViewer = () => {
	const [numPages, setNumPages] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);
	const [pdfUrl, setPdfUrl] = useState("");
	let { filterId } = useParams();
	console.log(filterId);
	console.log(books[filterId].url);
	useEffect(() => {
		let book = books[filterId];
		setPdfUrl(books[filterId].url);
		setNumPages(book.pages);
		
	}, []);  

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const previousPage = () => {
    if(pageNumber > 1)
	{
		let pageNo = pageNumber - 1;
		setPageNumber(pageNo)
	}
  }

  const nextPage = () => {
	  if(pageNumber < numPages)
	  {
		let pageNo = pageNumber +1;
		setPageNumber(pageNo);
	  }
  }
	
	return (
        <div className="book-viewer">
			<div className="book-header">
				<BookHeaderLogo />
				<BackButton bookDownloadUrl={books[filterId].downloadurl}/>
			</div>

			<div id="canvas_container">
				<Document file={books[filterId].url} options={{ workerSrc: "/pdf.worker.js" }} onLoadSuccess={onDocumentLoadSuccess}>
					<Page pageNumber={pageNumber}/>
					<div className="pdf-buttons">
						<div>
							<p>
								Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
							</p>
							<button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
								Previous
							</button>
							<button type="button"  disabled={pageNumber >= numPages}  onClick={nextPage}>
								Next
							</button>
						</div>
					</div>

				</Document>
			</div>
		</div>
	)
}

export default PDFViewer